/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 cyberpunk.glb --transform
HUBS_blenderExporterVersion: 0.0.9
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "./models/cyberpunk/source/cyberpunk-transformed.glb"
  );
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Room">
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93150"
          geometry={nodes.Tile93150.geometry}
          material={materials.Material}
          position={[-1, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93151"
          geometry={nodes.Tile93151.geometry}
          material={materials.Material}
          position={[-1, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93152"
          geometry={nodes.Tile93152.geometry}
          material={materials.Material}
          position={[-1, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93153"
          geometry={nodes.Tile93153.geometry}
          material={materials.Material}
          position={[-1, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93154"
          geometry={nodes.Tile93154.geometry}
          material={materials.Material}
          position={[-1, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93156"
          geometry={nodes.Tile93156.geometry}
          material={materials.Material}
          position={[-1, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93157"
          geometry={nodes.Tile93157.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93159"
          geometry={nodes.Tile93159.geometry}
          material={materials.Material}
          position={[-1, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93160"
          geometry={nodes.Tile93160.geometry}
          material={materials.Material}
          position={[-2, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93161"
          geometry={nodes.Tile93161.geometry}
          material={materials.Material}
          position={[-1, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93162"
          geometry={nodes.Tile93162.geometry}
          material={materials.Material}
          position={[-2, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10140"
          geometry={nodes.Tile10140.geometry}
          material={materials.Material}
          position={[-5, 2.5, -1]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10139"
          geometry={nodes.Tile10139.geometry}
          material={materials.Material}
          position={[-2, 3, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93170"
          geometry={nodes.Tile93170.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93171"
          geometry={nodes.Tile93171.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93173"
          geometry={nodes.Tile93173.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93174"
          geometry={nodes.Tile93174.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93175"
          geometry={nodes.Tile93175.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93176"
          geometry={nodes.Tile93176.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10133"
          geometry={nodes.Tile10133.geometry}
          material={materials.Material}
          position={[-0.5, 2.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 1, 3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93178"
          geometry={nodes.Tile93178.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93180"
          geometry={nodes.Tile93180.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93196"
          geometry={nodes.Tile93196.geometry}
          material={materials.Material}
          position={[0, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93197"
          geometry={nodes.Tile93197.geometry}
          material={materials.Material}
          position={[0, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93198"
          geometry={nodes.Tile93198.geometry}
          material={materials.Material}
          position={[0, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93199"
          geometry={nodes.Tile93199.geometry}
          material={materials.Material}
          position={[0, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93200"
          geometry={nodes.Tile93200.geometry}
          material={materials.Material}
          position={[0, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93201"
          geometry={nodes.Tile93201.geometry}
          material={materials.Material}
          position={[0, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93202"
          geometry={nodes.Tile93202.geometry}
          material={materials.Material}
          position={[0, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93203"
          geometry={nodes.Tile93203.geometry}
          material={materials.Material}
          position={[0, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93204"
          geometry={nodes.Tile93204.geometry}
          material={materials.Material}
          position={[0, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10111"
          geometry={nodes.Tile10111.geometry}
          material={materials.Material}
          position={[0, 2.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 1, 3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10157"
          geometry={nodes.Tile10157.geometry}
          material={materials.Material}
          position={[1.5, 3, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11095"
          geometry={nodes.Tile11095.geometry}
          material={materials.Material}
          position={[1, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11098"
          geometry={nodes.Tile11098.geometry}
          material={materials.Material}
          position={[1, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93206"
          geometry={nodes.Tile93206.geometry}
          material={materials.Material}
          position={[1.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10177"
          geometry={nodes.Tile10177.geometry}
          material={materials.Material}
          position={[-0.5, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10162"
          geometry={nodes.Tile10162.geometry}
          material={materials.Material}
          position={[-5, 4, -2.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11123"
          geometry={nodes.Tile11123.geometry}
          material={materials.Material}
          position={[4, 0.5, -5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11127"
          geometry={nodes.Tile11127.geometry}
          material={materials.Material}
          position={[2.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11136"
          geometry={nodes.Tile11136.geometry}
          material={materials.Material}
          position={[4, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11148"
          geometry={nodes.Tile11148.geometry}
          material={materials.Material}
          position={[4, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10187"
          geometry={nodes.Tile10187.geometry}
          material={materials.Material}
          position={[6, 4, -4]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10198"
          geometry={nodes.Tile10198.geometry}
          material={materials.Material}
          position={[6, 4, -2]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11025"
          geometry={nodes.Tile11025.geometry}
          material={materials.Material}
          position={[-4.5, 3, 1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11185"
          geometry={nodes.Tile11185.geometry}
          material={materials.Material}
          position={[4.5, 2.5, 1.51]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11194"
          geometry={nodes.Tile11194.geometry}
          material={materials.Material}
          position={[-4, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11210"
          geometry={nodes.Tile11210.geometry}
          material={materials.Material}
          position={[2.5, 0, 0.5]}
          scale={[3, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11218"
          geometry={nodes.Tile11218.geometry}
          material={materials.Material}
          position={[-1.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11224"
          geometry={nodes.Tile11224.geometry}
          material={materials.Material}
          position={[0.5, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11230"
          geometry={nodes.Tile11230.geometry}
          material={materials.Material}
          position={[-4.5, 2.5, 1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11248"
          geometry={nodes.Tile11248.geometry}
          material={materials.Material}
          position={[3, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11253"
          geometry={nodes.Tile11253.geometry}
          material={materials.Material}
          position={[-4.5, 1.5, 1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11254"
          geometry={nodes.Tile11254.geometry}
          material={materials.Material}
          position={[-4.5, 1, 1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11255"
          geometry={nodes.Tile11255.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, 1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11277"
          geometry={nodes.Tile11277.geometry}
          material={materials.Material}
          position={[4.5, 1.5, 1.51]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11278"
          geometry={nodes.Tile11278.geometry}
          material={materials.Material}
          position={[4.5, 1, 1.51]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11279"
          geometry={nodes.Tile11279.geometry}
          material={materials.Material}
          position={[4.5, 0.5, 1.51]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11280"
          geometry={nodes.Tile11280.geometry}
          material={materials.Material}
          position={[4.5, 3, 1.51]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11308"
          geometry={nodes.Tile11308.geometry}
          material={materials.Material}
          position={[1, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11312"
          geometry={nodes.Tile11312.geometry}
          material={materials.Material}
          position={[3, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11313"
          geometry={nodes.Tile11313.geometry}
          material={materials.Material}
          position={[3.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11326"
          geometry={nodes.Tile11326.geometry}
          material={materials.Material}
          position={[1, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11329"
          geometry={nodes.Tile11329.geometry}
          material={materials.Material}
          position={[-2, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11334"
          geometry={nodes.Tile11334.geometry}
          material={materials.Material}
          position={[-3, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[3, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11337"
          geometry={nodes.Tile11337.geometry}
          material={materials.Material}
          position={[-1.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11338"
          geometry={nodes.Tile11338.geometry}
          material={materials.Material}
          position={[-1, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11341"
          geometry={nodes.Tile11341.geometry}
          material={materials.Material}
          position={[-2.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11342"
          geometry={nodes.Tile11342.geometry}
          material={materials.Material}
          position={[-2, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11343"
          geometry={nodes.Tile11343.geometry}
          material={materials.Material}
          position={[-1.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11348"
          geometry={nodes.Tile11348.geometry}
          material={materials.Material}
          position={[-3.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11361"
          geometry={nodes.Tile11361.geometry}
          material={materials.Material}
          position={[-3, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11366"
          geometry={nodes.Tile11366.geometry}
          material={materials.Material}
          position={[4, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11396"
          geometry={nodes.Tile11396.geometry}
          material={materials.Material}
          position={[-2.5, 0, 5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11408"
          geometry={nodes.Tile11408.geometry}
          material={materials.Material}
          position={[2, 0, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11440"
          geometry={nodes.Tile11440.geometry}
          material={materials.Material}
          position={[2, 3.5, 9.5]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
          scale={[8, 1, 4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10104"
          geometry={nodes.Tile10104.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11021"
          geometry={nodes.Tile11021.geometry}
          material={materials.Material}
          position={[-2.5, 1, -1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10105"
          geometry={nodes.Tile10105.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10106"
          geometry={nodes.Tile10106.geometry}
          material={materials.Material}
          position={[-4.5, 1, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10107"
          geometry={nodes.Tile10107.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10108"
          geometry={nodes.Tile10108.geometry}
          material={materials.Material}
          position={[-4.5, 1, -4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10110"
          geometry={nodes.Tile10110.geometry}
          material={materials.Material}
          position={[-4.5, 1, -4.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10135"
          geometry={nodes.Tile10135.geometry}
          material={materials.Material}
          position={[-5, 3.5, -2]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11068"
          geometry={nodes.Tile11068.geometry}
          material={materials.Material}
          position={[-4, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11070"
          geometry={nodes.Tile11070.geometry}
          material={materials.Material}
          position={[-3, 1, -1]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11022"
          geometry={nodes.Tile11022.geometry}
          material={materials.Material}
          position={[-3.5, 1, -1]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11024"
          geometry={nodes.Tile11024.geometry}
          material={materials.Material}
          position={[-4, 1, -1]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11074"
          geometry={nodes.Tile11074.geometry}
          material={materials.Material}
          position={[-5, 1, -1]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10150"
          geometry={nodes.Tile10150.geometry}
          material={materials.Material}
          position={[-5, 4, 0]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11071"
          geometry={nodes.Tile11071.geometry}
          material={materials.Material}
          position={[-4.5, 1, -1]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10109"
          geometry={nodes.Tile10109.geometry}
          material={materials.Material}
          position={[-5, 1, -4]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11263"
          geometry={nodes.Tile11263.geometry}
          material={materials.Material}
          position={[0, 0.5, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11264"
          geometry={nodes.Tile11264.geometry}
          material={materials.Material}
          position={[0, 2.5, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11086"
          geometry={nodes.Tile11086.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1, -1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11087"
          geometry={nodes.Tile11087.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, 0]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11265"
          geometry={nodes.Tile11265.geometry}
          material={materials.Material}
          position={[-0.5, 2.5, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1, -1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11266"
          geometry={nodes.Tile11266.geometry}
          material={materials.Material}
          position={[-0.5, 2.5, 0]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10160"
          geometry={nodes.Tile10160.geometry}
          material={materials.Material}
          position={[-5, 4, -4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10161"
          geometry={nodes.Tile10161.geometry}
          material={materials.Material}
          position={[-5, 4, -2]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11143"
          geometry={nodes.Tile11143.geometry}
          material={materials.Material}
          position={[4, 1, -1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11146"
          geometry={nodes.Tile11146.geometry}
          material={materials.Material}
          position={[3.5, 1, -1]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11283"
          geometry={nodes.Tile11283.geometry}
          material={materials.Material}
          position={[2.5, 1, -1]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11285"
          geometry={nodes.Tile11285.geometry}
          material={materials.Material}
          position={[2.5, 1, -0.5]}
          rotation={[Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11298"
          geometry={nodes.Tile11298.geometry}
          material={materials.Material}
          position={[5, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11302"
          geometry={nodes.Tile11302.geometry}
          material={materials.Material}
          position={[1.5, 1, -4.5]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11303"
          geometry={nodes.Tile11303.geometry}
          material={materials.Material}
          position={[1.5, 1, -5]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11304"
          geometry={nodes.Tile11304.geometry}
          material={materials.Material}
          position={[1, 1, -4]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11305"
          geometry={nodes.Tile11305.geometry}
          material={materials.Material}
          position={[1.5, 1, -4]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11306"
          geometry={nodes.Tile11306.geometry}
          material={materials.Material}
          position={[0.5, 1, -4.5]}
          rotation={[Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11307"
          geometry={nodes.Tile11307.geometry}
          material={materials.Material}
          position={[0.5, 1, -4]}
          rotation={[Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11403"
          geometry={nodes.Tile11403.geometry}
          material={materials.Material}
          position={[-0.5, 2.5, -0.5]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11140"
          geometry={nodes.Tile11140.geometry}
          material={materials.Material}
          position={[3, 1, -1]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10209"
          geometry={nodes.Tile10209.geometry}
          material={materials.Material}
          position={[-3, 4, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10223"
          geometry={nodes.Tile10223.geometry}
          material={materials.Material}
          position={[-3, 3.5, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10228"
          geometry={nodes.Tile10228.geometry}
          material={materials.Material}
          position={[-3, 2.5, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10233"
          geometry={nodes.Tile10233.geometry}
          material={materials.Material}
          position={[-3, 2, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10234"
          geometry={nodes.Tile10234.geometry}
          material={materials.Material}
          position={[-3, 1.5, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10235"
          geometry={nodes.Tile10235.geometry}
          material={materials.Material}
          position={[3, 4, 8.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10238"
          geometry={nodes.Tile10238.geometry}
          material={materials.Material}
          position={[3, 3.5, 8.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10240"
          geometry={nodes.Tile10240.geometry}
          material={materials.Material}
          position={[3, 2.5, 8.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10241"
          geometry={nodes.Tile10241.geometry}
          material={materials.Material}
          position={[3, 2, 8.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10242"
          geometry={nodes.Tile10242.geometry}
          material={materials.Material}
          position={[3, 1.5, 8.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11158"
          geometry={nodes.Tile11158.geometry}
          material={materials.Material}
          position={[-5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11182"
          geometry={nodes.Tile11182.geometry}
          material={materials.Material}
          position={[-2.5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11216"
          geometry={nodes.Tile11216.geometry}
          material={materials.Material}
          position={[1.5, 0, 6]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11221"
          geometry={nodes.Tile11221.geometry}
          material={materials.Material}
          position={[2, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11225"
          geometry={nodes.Tile11225.geometry}
          material={materials.Material}
          position={[1, 0, 6]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11235"
          geometry={nodes.Tile11235.geometry}
          material={materials.Material}
          position={[-5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11245"
          geometry={nodes.Tile11245.geometry}
          material={materials.Material}
          position={[1.5, 0, 7.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11272"
          geometry={nodes.Tile11272.geometry}
          material={materials.Material}
          position={[4.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11274"
          geometry={nodes.Tile11274.geometry}
          material={materials.Material}
          position={[4.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11310"
          geometry={nodes.Tile11310.geometry}
          material={materials.Material}
          position={[2, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11335"
          geometry={nodes.Tile11335.geometry}
          material={materials.Material}
          position={[-2.5, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11351"
          geometry={nodes.Tile11351.geometry}
          material={materials.Material}
          position={[1, 0, 7.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11355"
          geometry={nodes.Tile11355.geometry}
          material={materials.Material}
          position={[1.5, 0, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11356"
          geometry={nodes.Tile11356.geometry}
          material={materials.Material}
          position={[1, 0, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11358"
          geometry={nodes.Tile11358.geometry}
          material={materials.Material}
          position={[-0.5, 0, 6]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11359"
          geometry={nodes.Tile11359.geometry}
          material={materials.Material}
          position={[-1, 0, 6]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11362"
          geometry={nodes.Tile11362.geometry}
          material={materials.Material}
          position={[-0.5, 0, 7.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11377"
          geometry={nodes.Tile11377.geometry}
          material={materials.Material}
          position={[-1, 0, 7.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11379"
          geometry={nodes.Tile11379.geometry}
          material={materials.Material}
          position={[-0.5, 0, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11380"
          geometry={nodes.Tile11380.geometry}
          material={materials.Material}
          position={[-1, 0, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11394"
          geometry={nodes.Tile11394.geometry}
          material={materials.Material}
          position={[2.5, 0, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11397"
          geometry={nodes.Tile11397.geometry}
          material={materials.Material}
          position={[2, 0, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11400"
          geometry={nodes.Tile11400.geometry}
          material={materials.Material}
          position={[-1.5, 0, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11401"
          geometry={nodes.Tile11401.geometry}
          material={materials.Material}
          position={[-2, 0, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11404"
          geometry={nodes.Tile11404.geometry}
          material={materials.Material}
          position={[-2.5, 0, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11418"
          geometry={nodes.Tile11418.geometry}
          material={materials.Material}
          position={[-3, 1, 5.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11419"
          geometry={nodes.Tile11419.geometry}
          material={materials.Material}
          position={[-3, 1, 6.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11420"
          geometry={nodes.Tile11420.geometry}
          material={materials.Material}
          position={[-3, 1, 7]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11421"
          geometry={nodes.Tile11421.geometry}
          material={materials.Material}
          position={[-3, 1, 8]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11422"
          geometry={nodes.Tile11422.geometry}
          material={materials.Material}
          position={[3, 1, 7.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11423"
          geometry={nodes.Tile11423.geometry}
          material={materials.Material}
          position={[3, 1, 6.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11424"
          geometry={nodes.Tile11424.geometry}
          material={materials.Material}
          position={[3, 1, 6]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11425"
          geometry={nodes.Tile11425.geometry}
          material={materials.Material}
          position={[3, 1, 5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11438"
          geometry={nodes.Tile11438.geometry}
          material={materials.Material}
          position={[0.5, 0, 9.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11439"
          geometry={nodes.Tile11439.geometry}
          material={materials.Material}
          position={[0, 0, 9.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11443"
          geometry={nodes.Tile11443.geometry}
          material={materials.Material}
          position={[2.5, 4, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11444"
          geometry={nodes.Tile11444.geometry}
          material={materials.Material}
          position={[2.5, 3, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11445"
          geometry={nodes.Tile11445.geometry}
          material={materials.Material}
          position={[2.5, 2.5, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11446"
          geometry={nodes.Tile11446.geometry}
          material={materials.Material}
          position={[2.5, 1.5, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11447"
          geometry={nodes.Tile11447.geometry}
          material={materials.Material}
          position={[-2, 4, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11448"
          geometry={nodes.Tile11448.geometry}
          material={materials.Material}
          position={[-2, 3, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11449"
          geometry={nodes.Tile11449.geometry}
          material={materials.Material}
          position={[-2, 2.5, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11450"
          geometry={nodes.Tile11450.geometry}
          material={materials.Material}
          position={[-2, 1.5, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11451"
          geometry={nodes.Tile11451.geometry}
          material={materials.Material}
          position={[-2.5, 1, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11453"
          geometry={nodes.Tile11453.geometry}
          material={materials.Material}
          position={[-2.5, 1, 8.5]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11454"
          geometry={nodes.Tile11454.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11455"
          geometry={nodes.Tile11455.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, 8.5]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11456"
          geometry={nodes.Tile11456.geometry}
          material={materials.Material}
          position={[3, 1, 8.5]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11458"
          geometry={nodes.Tile11458.geometry}
          material={materials.Material}
          position={[2.5, 1, 8.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11459"
          geometry={nodes.Tile11459.geometry}
          material={materials.Material}
          position={[3, 0.5, 8.5]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11460"
          geometry={nodes.Tile11460.geometry}
          material={materials.Material}
          position={[2.5, 0.5, 8.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11461"
          geometry={nodes.Tile11461.geometry}
          material={materials.Material}
          position={[3, 2, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11463"
          geometry={nodes.Tile11463.geometry}
          material={materials.Material}
          position={[2.5, 2, 9]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11465"
          geometry={nodes.Tile11465.geometry}
          material={materials.Material}
          position={[2.5, 1.5, 9]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11466"
          geometry={nodes.Tile11466.geometry}
          material={materials.Material}
          position={[2.5, 3, 9.5]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11468"
          geometry={nodes.Tile11468.geometry}
          material={materials.Material}
          position={[2.5, 3, 9]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11469"
          geometry={nodes.Tile11469.geometry}
          material={materials.Material}
          position={[2.5, 3.5, 9.5]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11470"
          geometry={nodes.Tile11470.geometry}
          material={materials.Material}
          position={[2.5, 3.5, 9]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11473"
          geometry={nodes.Tile11473.geometry}
          material={materials.Material}
          position={[-2.5, 2, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11475"
          geometry={nodes.Tile11475.geometry}
          material={materials.Material}
          position={[-3, 2, 9]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11476"
          geometry={nodes.Tile11476.geometry}
          material={materials.Material}
          position={[-2.5, 1.5, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11477"
          geometry={nodes.Tile11477.geometry}
          material={materials.Material}
          position={[-3, 1.5, 9]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11478"
          geometry={nodes.Tile11478.geometry}
          material={materials.Material}
          position={[-3, 3, 9.5]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11481"
          geometry={nodes.Tile11481.geometry}
          material={materials.Material}
          position={[-3, 3.5, 9.5]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11482"
          geometry={nodes.Tile11482.geometry}
          material={materials.Material}
          position={[-3, 3.5, 9]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11489"
          geometry={nodes.Tile11489.geometry}
          material={materials.Material}
          position={[-2.5, 3.5, 9]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11491"
          geometry={nodes.Tile11491.geometry}
          material={materials.Material}
          position={[-2.5, 3, 9]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11492"
          geometry={nodes.Tile11492.geometry}
          material={materials.Material}
          position={[-2.5, 1.5, 9]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11493"
          geometry={nodes.Tile11493.geometry}
          material={materials.Material}
          position={[-2.5, 1, 9]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11013"
          geometry={nodes.Tile11013.geometry}
          material={materials.Material}
          position={[-1.5, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11027"
          geometry={nodes.Tile11027.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11028"
          geometry={nodes.Tile11028.geometry}
          material={materials.Material}
          position={[-3, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11030"
          geometry={nodes.Tile11030.geometry}
          material={materials.Material}
          position={[-4, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11031"
          geometry={nodes.Tile11031.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11033"
          geometry={nodes.Tile11033.geometry}
          material={materials.Material}
          position={[-2, 0.5, -2.5]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11034"
          geometry={nodes.Tile11034.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11035"
          geometry={nodes.Tile11035.geometry}
          material={materials.Material}
          position={[-3, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11036"
          geometry={nodes.Tile11036.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11037"
          geometry={nodes.Tile11037.geometry}
          material={materials.Material}
          position={[-4, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11038"
          geometry={nodes.Tile11038.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11040"
          geometry={nodes.Tile11040.geometry}
          material={materials.Material}
          position={[-2, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11042"
          geometry={nodes.Tile11042.geometry}
          material={materials.Material}
          position={[-3, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11043"
          geometry={nodes.Tile11043.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11044"
          geometry={nodes.Tile11044.geometry}
          material={materials.Material}
          position={[-4, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11045"
          geometry={nodes.Tile11045.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11046"
          geometry={nodes.Tile11046.geometry}
          material={materials.Material}
          position={[-1.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11047"
          geometry={nodes.Tile11047.geometry}
          material={materials.Material}
          position={[-2, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11048"
          geometry={nodes.Tile11048.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11049"
          geometry={nodes.Tile11049.geometry}
          material={materials.Material}
          position={[-3, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11050"
          geometry={nodes.Tile11050.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11051"
          geometry={nodes.Tile11051.geometry}
          material={materials.Material}
          position={[-4, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11052"
          geometry={nodes.Tile11052.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11053"
          geometry={nodes.Tile11053.geometry}
          material={materials.Material}
          position={[-1.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11054"
          geometry={nodes.Tile11054.geometry}
          material={materials.Material}
          position={[-2, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11056"
          geometry={nodes.Tile11056.geometry}
          material={materials.Material}
          position={[-3, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11057"
          geometry={nodes.Tile11057.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11058"
          geometry={nodes.Tile11058.geometry}
          material={materials.Material}
          position={[-4, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93158"
          geometry={nodes.Tile93158.geometry}
          material={materials.Material}
          position={[-2, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11016"
          geometry={nodes.Tile11016.geometry}
          material={materials.Material}
          position={[-1.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11059"
          geometry={nodes.Tile11059.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11060"
          geometry={nodes.Tile11060.geometry}
          material={materials.Material}
          position={[-3, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11061"
          geometry={nodes.Tile11061.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11062"
          geometry={nodes.Tile11062.geometry}
          material={materials.Material}
          position={[-4, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11063"
          geometry={nodes.Tile11063.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10115"
          geometry={nodes.Tile10115.geometry}
          material={materials.Material}
          position={[-5, 2, -4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10121"
          geometry={nodes.Tile10121.geometry}
          material={materials.Material}
          position={[-5, 3, -1.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1, 1, 4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10122"
          geometry={nodes.Tile10122.geometry}
          material={materials.Material}
          position={[-5, 1.5, -0.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10124"
          geometry={nodes.Tile10124.geometry}
          material={materials.Material}
          position={[-5, 2.5, -0.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11029"
          geometry={nodes.Tile11029.geometry}
          material={materials.Material}
          position={[-1.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11041"
          geometry={nodes.Tile11041.geometry}
          material={materials.Material}
          position={[-2, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10118"
          geometry={nodes.Tile10118.geometry}
          material={materials.Material}
          position={[-5, 2, -4.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10119"
          geometry={nodes.Tile10119.geometry}
          material={materials.Material}
          position={[-5, 2.5, -4.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10120"
          geometry={nodes.Tile10120.geometry}
          material={materials.Material}
          position={[-5, 3, -4.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10134"
          geometry={nodes.Tile10134.geometry}
          material={materials.Material}
          position={[-5, 3.5, -4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10136"
          geometry={nodes.Tile10136.geometry}
          material={materials.Material}
          position={[-5, 3.5, -2.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10137"
          geometry={nodes.Tile10137.geometry}
          material={materials.Material}
          position={[-5, 3.5, -3]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11064"
          geometry={nodes.Tile11064.geometry}
          material={materials.Material}
          position={[-1.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11065"
          geometry={nodes.Tile11065.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11066"
          geometry={nodes.Tile11066.geometry}
          material={materials.Material}
          position={[-3, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11067"
          geometry={nodes.Tile11067.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11069"
          geometry={nodes.Tile11069.geometry}
          material={materials.Material}
          position={[-4.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10138"
          geometry={nodes.Tile10138.geometry}
          material={materials.Material}
          position={[-5, 1.5, -1]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11072"
          geometry={nodes.Tile11072.geometry}
          material={materials.Material}
          position={[-2.5, 1, -0.5]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10143"
          geometry={nodes.Tile10143.geometry}
          material={materials.Material}
          position={[-2.5, 3.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10144"
          geometry={nodes.Tile10144.geometry}
          material={materials.Material}
          position={[-4, 3.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11023"
          geometry={nodes.Tile11023.geometry}
          material={materials.Material}
          position={[-3, 1, -0.5]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11073"
          geometry={nodes.Tile11073.geometry}
          material={materials.Material}
          position={[-3.5, 1, -0.5]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10117"
          geometry={nodes.Tile10117.geometry}
          material={materials.Material}
          position={[-2, 2.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 1, 3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10123"
          geometry={nodes.Tile10123.geometry}
          material={materials.Material}
          position={[-2, 1, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10146"
          geometry={nodes.Tile10146.geometry}
          material={materials.Material}
          position={[-1.5, 3.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10147"
          geometry={nodes.Tile10147.geometry}
          material={materials.Material}
          position={[-1, 3.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10125"
          geometry={nodes.Tile10125.geometry}
          material={materials.Material}
          position={[-5, 3.5, -1.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10141"
          geometry={nodes.Tile10141.geometry}
          material={materials.Material}
          position={[-0.5, 3.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10148"
          geometry={nodes.Tile10148.geometry}
          material={materials.Material}
          position={[-0.5, 3, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10149"
          geometry={nodes.Tile10149.geometry}
          material={materials.Material}
          position={[-0.5, 1, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11077"
          geometry={nodes.Tile11077.geometry}
          material={materials.Material}
          position={[-3.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11078"
          geometry={nodes.Tile11078.geometry}
          material={materials.Material}
          position={[-4, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11079"
          geometry={nodes.Tile11079.geometry}
          material={materials.Material}
          position={[-4.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11080"
          geometry={nodes.Tile11080.geometry}
          material={materials.Material}
          position={[-5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11083"
          geometry={nodes.Tile11083.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11085"
          geometry={nodes.Tile11085.geometry}
          material={materials.Material}
          position={[-3, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11088"
          geometry={nodes.Tile11088.geometry}
          material={materials.Material}
          position={[-4, 1, -0.5]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10114"
          geometry={nodes.Tile10114.geometry}
          material={materials.Material}
          position={[-5, 1.5, -4.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10153"
          geometry={nodes.Tile10153.geometry}
          material={materials.Material}
          position={[0, 3, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10154"
          geometry={nodes.Tile10154.geometry}
          material={materials.Material}
          position={[0, 1, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10158"
          geometry={nodes.Tile10158.geometry}
          material={materials.Material}
          position={[1.5, 2.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 1, 3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10159"
          geometry={nodes.Tile10159.geometry}
          material={materials.Material}
          position={[1.5, 1, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11081"
          geometry={nodes.Tile11081.geometry}
          material={materials.Material}
          position={[0.5, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11082"
          geometry={nodes.Tile11082.geometry}
          material={materials.Material}
          position={[0.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11089"
          geometry={nodes.Tile11089.geometry}
          material={materials.Material}
          position={[0.5, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11091"
          geometry={nodes.Tile11091.geometry}
          material={materials.Material}
          position={[0.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11092"
          geometry={nodes.Tile11092.geometry}
          material={materials.Material}
          position={[0.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11093"
          geometry={nodes.Tile11093.geometry}
          material={materials.Material}
          position={[0.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11094"
          geometry={nodes.Tile11094.geometry}
          material={materials.Material}
          position={[1, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11096"
          geometry={nodes.Tile11096.geometry}
          material={materials.Material}
          position={[1, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11099"
          geometry={nodes.Tile11099.geometry}
          material={materials.Material}
          position={[1, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11100"
          geometry={nodes.Tile11100.geometry}
          material={materials.Material}
          position={[1, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11101"
          geometry={nodes.Tile11101.geometry}
          material={materials.Material}
          position={[1.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11102"
          geometry={nodes.Tile11102.geometry}
          material={materials.Material}
          position={[1.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11103"
          geometry={nodes.Tile11103.geometry}
          material={materials.Material}
          position={[1.5, 0.5, -3]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11104"
          geometry={nodes.Tile11104.geometry}
          material={materials.Material}
          position={[1.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93205"
          geometry={nodes.Tile93205.geometry}
          material={materials.Material}
          position={[1.5, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11106"
          geometry={nodes.Tile11106.geometry}
          material={materials.Material}
          position={[2, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11107"
          geometry={nodes.Tile11107.geometry}
          material={materials.Material}
          position={[2, 0.5, -5]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11108"
          geometry={nodes.Tile11108.geometry}
          material={materials.Material}
          position={[2, 0.5, -5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11109"
          geometry={nodes.Tile11109.geometry}
          material={materials.Material}
          position={[2, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11111"
          geometry={nodes.Tile11111.geometry}
          material={materials.Material}
          position={[2, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11113"
          geometry={nodes.Tile11113.geometry}
          material={materials.Material}
          position={[2, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11114"
          geometry={nodes.Tile11114.geometry}
          material={materials.Material}
          position={[2, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11115"
          geometry={nodes.Tile11115.geometry}
          material={materials.Material}
          position={[2, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10163"
          geometry={nodes.Tile10163.geometry}
          material={materials.Material}
          position={[2.5, 3.5, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10166"
          geometry={nodes.Tile10166.geometry}
          material={materials.Material}
          position={[4, 3.5, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10167"
          geometry={nodes.Tile10167.geometry}
          material={materials.Material}
          position={[2.5, 1, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10168"
          geometry={nodes.Tile10168.geometry}
          material={materials.Material}
          position={[4, 1, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10164"
          geometry={nodes.Tile10164.geometry}
          material={materials.Material}
          position={[2.5, 4, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10165"
          geometry={nodes.Tile10165.geometry}
          material={materials.Material}
          position={[4, 4, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10169"
          geometry={nodes.Tile10169.geometry}
          material={materials.Material}
          position={[3, 4, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10170"
          geometry={nodes.Tile10170.geometry}
          material={materials.Material}
          position={[3.5, 4, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10171"
          geometry={nodes.Tile10171.geometry}
          material={materials.Material}
          position={[-5, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[2, 1, 6]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10172"
          geometry={nodes.Tile10172.geometry}
          material={materials.Material}
          position={[-2.5, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10173"
          geometry={nodes.Tile10173.geometry}
          material={materials.Material}
          position={[-4, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10174"
          geometry={nodes.Tile10174.geometry}
          material={materials.Material}
          position={[-3.5, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10176"
          geometry={nodes.Tile10176.geometry}
          material={materials.Material}
          position={[-1, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10178"
          geometry={nodes.Tile10178.geometry}
          material={materials.Material}
          position={[0, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10179"
          geometry={nodes.Tile10179.geometry}
          material={materials.Material}
          position={[0.5, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10180"
          geometry={nodes.Tile10180.geometry}
          material={materials.Material}
          position={[1.5, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11118"
          geometry={nodes.Tile11118.geometry}
          material={materials.Material}
          position={[2, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11121"
          geometry={nodes.Tile11121.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -2.5]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10183"
          geometry={nodes.Tile10183.geometry}
          material={materials.Material}
          position={[-5, 4, -3]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10184"
          geometry={nodes.Tile10184.geometry}
          material={materials.Material}
          position={[-5, 4, -0.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11026"
          geometry={nodes.Tile11026.geometry}
          material={materials.Material}
          position={[2.5, 0.5, -5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11032"
          geometry={nodes.Tile11032.geometry}
          material={materials.Material}
          position={[3, 0.5, -5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11122"
          geometry={nodes.Tile11122.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11124"
          geometry={nodes.Tile11124.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11125"
          geometry={nodes.Tile11125.geometry}
          material={materials.Material}
          position={[4, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11126"
          geometry={nodes.Tile11126.geometry}
          material={materials.Material}
          position={[2.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11128"
          geometry={nodes.Tile11128.geometry}
          material={materials.Material}
          position={[2.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11129"
          geometry={nodes.Tile11129.geometry}
          material={materials.Material}
          position={[3, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11130"
          geometry={nodes.Tile11130.geometry}
          material={materials.Material}
          position={[3, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11131"
          geometry={nodes.Tile11131.geometry}
          material={materials.Material}
          position={[3, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11132"
          geometry={nodes.Tile11132.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11133"
          geometry={nodes.Tile11133.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11134"
          geometry={nodes.Tile11134.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11135"
          geometry={nodes.Tile11135.geometry}
          material={materials.Material}
          position={[4, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11137"
          geometry={nodes.Tile11137.geometry}
          material={materials.Material}
          position={[4, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11138"
          geometry={nodes.Tile11138.geometry}
          material={materials.Material}
          position={[2.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11139"
          geometry={nodes.Tile11139.geometry}
          material={materials.Material}
          position={[2.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11141"
          geometry={nodes.Tile11141.geometry}
          material={materials.Material}
          position={[3, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11142"
          geometry={nodes.Tile11142.geometry}
          material={materials.Material}
          position={[3, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11144"
          geometry={nodes.Tile11144.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11145"
          geometry={nodes.Tile11145.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11147"
          geometry={nodes.Tile11147.geometry}
          material={materials.Material}
          position={[4, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11149"
          geometry={nodes.Tile11149.geometry}
          material={materials.Material}
          position={[4, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11154"
          geometry={nodes.Tile11154.geometry}
          material={materials.Material}
          position={[4.5, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11155"
          geometry={nodes.Tile11155.geometry}
          material={materials.Material}
          position={[4.5, 0.5, -5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11166"
          geometry={nodes.Tile11166.geometry}
          material={materials.Material}
          position={[4.5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11167"
          geometry={nodes.Tile11167.geometry}
          material={materials.Material}
          position={[4.5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11168"
          geometry={nodes.Tile11168.geometry}
          material={materials.Material}
          position={[2.5, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11173"
          geometry={nodes.Tile11173.geometry}
          material={materials.Material}
          position={[4, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11184"
          geometry={nodes.Tile11184.geometry}
          material={materials.Material}
          position={[4.5, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11271"
          geometry={nodes.Tile11271.geometry}
          material={materials.Material}
          position={[4.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11282"
          geometry={nodes.Tile11282.geometry}
          material={materials.Material}
          position={[4, 1, -0.5]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11284"
          geometry={nodes.Tile11284.geometry}
          material={materials.Material}
          position={[3, 1, -0.5]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11286"
          geometry={nodes.Tile11286.geometry}
          material={materials.Material}
          position={[5, 0.5, -5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11287"
          geometry={nodes.Tile11287.geometry}
          material={materials.Material}
          position={[5.5, 0.5, -5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11164"
          geometry={nodes.Tile11164.geometry}
          material={materials.Material}
          position={[5, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11165"
          geometry={nodes.Tile11165.geometry}
          material={materials.Material}
          position={[5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11150"
          geometry={nodes.Tile11150.geometry}
          material={materials.Material}
          position={[5, 0.5, -3.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11151"
          geometry={nodes.Tile11151.geometry}
          material={materials.Material}
          position={[5, 0.5, -4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11116"
          geometry={nodes.Tile11116.geometry}
          material={materials.Material}
          position={[3, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11117"
          geometry={nodes.Tile11117.geometry}
          material={materials.Material}
          position={[3, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11120"
          geometry={nodes.Tile11120.geometry}
          material={materials.Material}
          position={[3.5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11288"
          geometry={nodes.Tile11288.geometry}
          material={materials.Material}
          position={[4, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11289"
          geometry={nodes.Tile11289.geometry}
          material={materials.Material}
          position={[4.5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11290"
          geometry={nodes.Tile11290.geometry}
          material={materials.Material}
          position={[5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11291"
          geometry={nodes.Tile11291.geometry}
          material={materials.Material}
          position={[5.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11292"
          geometry={nodes.Tile11292.geometry}
          material={materials.Material}
          position={[5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11293"
          geometry={nodes.Tile11293.geometry}
          material={materials.Material}
          position={[5.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11294"
          geometry={nodes.Tile11294.geometry}
          material={materials.Material}
          position={[5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11295"
          geometry={nodes.Tile11295.geometry}
          material={materials.Material}
          position={[5.5, 0.5, -2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11296"
          geometry={nodes.Tile11296.geometry}
          material={materials.Material}
          position={[5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11297"
          geometry={nodes.Tile11297.geometry}
          material={materials.Material}
          position={[5.5, 0.5, -1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10185"
          geometry={nodes.Tile10185.geometry}
          material={materials.Material}
          position={[6, 3.5, -4]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10186"
          geometry={nodes.Tile10186.geometry}
          material={materials.Material}
          position={[6, 3.5, -2.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10188"
          geometry={nodes.Tile10188.geometry}
          material={materials.Material}
          position={[6, 4, -2.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10189"
          geometry={nodes.Tile10189.geometry}
          material={materials.Material}
          position={[6, 4, -3.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10192"
          geometry={nodes.Tile10192.geometry}
          material={materials.Material}
          position={[6, 4, -3]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10193"
          geometry={nodes.Tile10193.geometry}
          material={materials.Material}
          position={[6, 3.5, -5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10194"
          geometry={nodes.Tile10194.geometry}
          material={materials.Material}
          position={[6, 4, -5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10196"
          geometry={nodes.Tile10196.geometry}
          material={materials.Material}
          position={[6, 4, -4.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10197"
          geometry={nodes.Tile10197.geometry}
          material={materials.Material}
          position={[6, 3.5, -2]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10199"
          geometry={nodes.Tile10199.geometry}
          material={materials.Material}
          position={[6, 3.5, -1.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10200"
          geometry={nodes.Tile10200.geometry}
          material={materials.Material}
          position={[6, 4, -1.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10201"
          geometry={nodes.Tile10201.geometry}
          material={materials.Material}
          position={[6, 3, -5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 1, 5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10202"
          geometry={nodes.Tile10202.geometry}
          material={materials.Material}
          position={[6, 3, -4.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 1, 5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10203"
          geometry={nodes.Tile10203.geometry}
          material={materials.Material}
          position={[6, 3, -2]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 1, 5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10204"
          geometry={nodes.Tile10204.geometry}
          material={materials.Material}
          position={[6, 3, -1.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 1, 5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11300"
          geometry={nodes.Tile11300.geometry}
          material={materials.Material}
          position={[5.5, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10208"
          geometry={nodes.Tile10208.geometry}
          material={materials.Material}
          position={[5, 4, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10211"
          geometry={nodes.Tile10211.geometry}
          material={materials.Material}
          position={[4.5, 2.5, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10213"
          geometry={nodes.Tile10213.geometry}
          material={materials.Material}
          position={[4.5, 2, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10215"
          geometry={nodes.Tile10215.geometry}
          material={materials.Material}
          position={[4.5, 1.5, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10217"
          geometry={nodes.Tile10217.geometry}
          material={materials.Material}
          position={[4.5, 1, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10207"
          geometry={nodes.Tile10207.geometry}
          material={materials.Material}
          position={[5, 4, -0.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[2, 1, 8]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10126"
          geometry={nodes.Tile10126.geometry}
          material={materials.Material}
          position={[5, 4, 0.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10127"
          geometry={nodes.Tile10127.geometry}
          material={materials.Material}
          position={[5, 4, 1]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10128"
          geometry={nodes.Tile10128.geometry}
          material={materials.Material}
          position={[5, 4, 1.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10129"
          geometry={nodes.Tile10129.geometry}
          material={materials.Material}
          position={[-5, 0.5, 0]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10130"
          geometry={nodes.Tile10130.geometry}
          material={materials.Material}
          position={[-5, 0.5, 0.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10131"
          geometry={nodes.Tile10131.geometry}
          material={materials.Material}
          position={[-4.98, 0.5, 2.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10132"
          geometry={nodes.Tile10132.geometry}
          material={materials.Material}
          position={[5, 0.5, 2]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10151"
          geometry={nodes.Tile10151.geometry}
          material={materials.Material}
          position={[5, 1, 2.5]}
          rotation={[Math.PI / 2, 0, 2.36]}
          scale={[3.99, 3.95, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10210"
          geometry={nodes.Tile10210.geometry}
          material={materials.Material}
          position={[5, 4, 2.5]}
          rotation={[Math.PI / 2, 0, 2.36]}
          scale={[3.99, 3.95, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10212"
          geometry={nodes.Tile10212.geometry}
          material={materials.Material}
          position={[-3.59, 1, 3.91]}
          rotation={[Math.PI / 2, 0, -2.36]}
          scale={[3.99, 3.95, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10214"
          geometry={nodes.Tile10214.geometry}
          material={materials.Material}
          position={[-3.59, 4, 3.91]}
          rotation={[Math.PI / 2, 0, -2.36]}
          scale={[3.99, 3.95, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10155"
          geometry={nodes.Tile10155.geometry}
          material={materials.Material}
          position={[5.5, 4, -0.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10220"
          geometry={nodes.Tile10220.geometry}
          material={materials.Material}
          position={[-3, 4, 8.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10225"
          geometry={nodes.Tile10225.geometry}
          material={materials.Material}
          position={[-3, 4, 6]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11409"
          geometry={nodes.Tile11409.geometry}
          material={materials.Material}
          position={[3.5, 1, -0.5]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10221"
          geometry={nodes.Tile10221.geometry}
          material={materials.Material}
          position={[-3, 3.5, 6]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10222"
          geometry={nodes.Tile10222.geometry}
          material={materials.Material}
          position={[-3, 3.5, 8.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10229"
          geometry={nodes.Tile10229.geometry}
          material={materials.Material}
          position={[3, 4, 4.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10231"
          geometry={nodes.Tile10231.geometry}
          material={materials.Material}
          position={[3, 4, 7]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10236"
          geometry={nodes.Tile10236.geometry}
          material={materials.Material}
          position={[3, 3.5, 7]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10237"
          geometry={nodes.Tile10237.geometry}
          material={materials.Material}
          position={[3, 3.5, 4.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[3, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10205"
          geometry={nodes.Tile10205.geometry}
          material={materials.Material}
          position={[4.5, 3, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93195"
          geometry={nodes.Tile93195.geometry}
          material={materials.Material}
          position={[-5, 3.5, 1.5]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93207"
          geometry={nodes.Tile93207.geometry}
          material={materials.Material}
          position={[5, 3.5, 1.01]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10142"
          geometry={nodes.Tile10142.geometry}
          material={materials.Material}
          position={[5, 4, 2]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10145"
          geometry={nodes.Tile10145.geometry}
          material={materials.Material}
          position={[-5, 4, 1]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10181"
          geometry={nodes.Tile10181.geometry}
          material={materials.Material}
          position={[-5, 4, 2]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10182"
          geometry={nodes.Tile10182.geometry}
          material={materials.Material}
          position={[-5, 4, 1.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10190"
          geometry={nodes.Tile10190.geometry}
          material={materials.Material}
          position={[-5, 4, 0.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10191"
          geometry={nodes.Tile10191.geometry}
          material={materials.Material}
          position={[-5, 4, 2.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10218"
          geometry={nodes.Tile10218.geometry}
          material={materials.Material}
          position={[3.35, 4, 4.15]}
          rotation={[Math.PI / 2, 0, 2.36]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10219"
          geometry={nodes.Tile10219.geometry}
          material={materials.Material}
          position={[-3, 4, 4.5]}
          rotation={[Math.PI / 2, 0, -2.36]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10224"
          geometry={nodes.Tile10224.geometry}
          material={materials.Material}
          position={[-3, 3.5, 4.5]}
          rotation={[Math.PI / 2, 0, -2.36]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10226"
          geometry={nodes.Tile10226.geometry}
          material={materials.Material}
          position={[-3, 3, 4.5]}
          rotation={[Math.PI / 2, 0, -2.36]}
          scale={[1, 1, 6]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10230"
          geometry={nodes.Tile10230.geometry}
          material={materials.Material}
          position={[3.35, 3.5, 4.15]}
          rotation={[Math.PI / 2, 0, 2.36]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10232"
          geometry={nodes.Tile10232.geometry}
          material={materials.Material}
          position={[3.35, 3, 4.15]}
          rotation={[Math.PI / 2, 0, 2.36]}
          scale={[1, 1, 6]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11112"
          geometry={nodes.Tile11112.geometry}
          material={materials.Material}
          position={[1.5, 0, 4.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11157"
          geometry={nodes.Tile11157.geometry}
          material={materials.Material}
          position={[-4.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11163"
          geometry={nodes.Tile11163.geometry}
          material={materials.Material}
          position={[-2.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11174"
          geometry={nodes.Tile11174.geometry}
          material={materials.Material}
          position={[-3.5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11175"
          geometry={nodes.Tile11175.geometry}
          material={materials.Material}
          position={[-4, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11176"
          geometry={nodes.Tile11176.geometry}
          material={materials.Material}
          position={[-4.5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11177"
          geometry={nodes.Tile11177.geometry}
          material={materials.Material}
          position={[-5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11179"
          geometry={nodes.Tile11179.geometry}
          material={materials.Material}
          position={[-1, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11180"
          geometry={nodes.Tile11180.geometry}
          material={materials.Material}
          position={[-1.5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11181"
          geometry={nodes.Tile11181.geometry}
          material={materials.Material}
          position={[-2, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11186"
          geometry={nodes.Tile11186.geometry}
          material={materials.Material}
          position={[2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11189"
          geometry={nodes.Tile11189.geometry}
          material={materials.Material}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11193"
          geometry={nodes.Tile11193.geometry}
          material={materials.Material}
          position={[-3.5, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11195"
          geometry={nodes.Tile11195.geometry}
          material={materials.Material}
          position={[-4.5, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11196"
          geometry={nodes.Tile11196.geometry}
          material={materials.Material}
          position={[-5, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11197"
          geometry={nodes.Tile11197.geometry}
          material={materials.Material}
          position={[-0.5, 0, 1]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11198"
          geometry={nodes.Tile11198.geometry}
          material={materials.Material}
          position={[-1, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11199"
          geometry={nodes.Tile11199.geometry}
          material={materials.Material}
          position={[-1.5, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11200"
          geometry={nodes.Tile11200.geometry}
          material={materials.Material}
          position={[-2, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11204"
          geometry={nodes.Tile11204.geometry}
          material={materials.Material}
          position={[1, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11205"
          geometry={nodes.Tile11205.geometry}
          material={materials.Material}
          position={[0.5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11206"
          geometry={nodes.Tile11206.geometry}
          material={materials.Material}
          position={[0, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11209"
          geometry={nodes.Tile11209.geometry}
          material={materials.Material}
          position={[3, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11212"
          geometry={nodes.Tile11212.geometry}
          material={materials.Material}
          position={[-3.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11213"
          geometry={nodes.Tile11213.geometry}
          material={materials.Material}
          position={[-4, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11214"
          geometry={nodes.Tile11214.geometry}
          material={materials.Material}
          position={[-4.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11215"
          geometry={nodes.Tile11215.geometry}
          material={materials.Material}
          position={[-5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11217"
          geometry={nodes.Tile11217.geometry}
          material={materials.Material}
          position={[-1, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11219"
          geometry={nodes.Tile11219.geometry}
          material={materials.Material}
          position={[-2, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11220"
          geometry={nodes.Tile11220.geometry}
          material={materials.Material}
          position={[-2.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11223"
          geometry={nodes.Tile11223.geometry}
          material={materials.Material}
          position={[1, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11226"
          geometry={nodes.Tile11226.geometry}
          material={materials.Material}
          position={[4, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11227"
          geometry={nodes.Tile11227.geometry}
          material={materials.Material}
          position={[3.5, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11228"
          geometry={nodes.Tile11228.geometry}
          material={materials.Material}
          position={[4, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11229"
          geometry={nodes.Tile11229.geometry}
          material={materials.Material}
          position={[1.5, 0, 6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11243"
          geometry={nodes.Tile11243.geometry}
          material={materials.Material}
          position={[1, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11244"
          geometry={nodes.Tile11244.geometry}
          material={materials.Material}
          position={[0.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11247"
          geometry={nodes.Tile11247.geometry}
          material={materials.Material}
          position={[3.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11249"
          geometry={nodes.Tile11249.geometry}
          material={materials.Material}
          position={[2.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11250"
          geometry={nodes.Tile11250.geometry}
          material={materials.Material}
          position={[2, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11251"
          geometry={nodes.Tile11251.geometry}
          material={materials.Material}
          position={[-4.5, 3.5, 1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11259"
          geometry={nodes.Tile11259.geometry}
          material={materials.Material}
          position={[0, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11267"
          geometry={nodes.Tile11267.geometry}
          material={materials.Material}
          position={[2.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11270"
          geometry={nodes.Tile11270.geometry}
          material={materials.Material}
          position={[3.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11273"
          geometry={nodes.Tile11273.geometry}
          material={materials.Material}
          position={[4.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11275"
          geometry={nodes.Tile11275.geometry}
          material={materials.Material}
          position={[4.5, 3.5, 1.51]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11281"
          geometry={nodes.Tile11281.geometry}
          material={materials.Material}
          position={[4.5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11301"
          geometry={nodes.Tile11301.geometry}
          material={materials.Material}
          position={[0.5, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11309"
          geometry={nodes.Tile11309.geometry}
          material={materials.Material}
          position={[1.5, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11314"
          geometry={nodes.Tile11314.geometry}
          material={materials.Material}
          position={[0, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11315"
          geometry={nodes.Tile11315.geometry}
          material={materials.Material}
          position={[0.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11316"
          geometry={nodes.Tile11316.geometry}
          material={materials.Material}
          position={[1, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11317"
          geometry={nodes.Tile11317.geometry}
          material={materials.Material}
          position={[1.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11320"
          geometry={nodes.Tile11320.geometry}
          material={materials.Material}
          position={[3, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11321"
          geometry={nodes.Tile11321.geometry}
          material={materials.Material}
          position={[3.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11322"
          geometry={nodes.Tile11322.geometry}
          material={materials.Material}
          position={[4, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11323"
          geometry={nodes.Tile11323.geometry}
          material={materials.Material}
          position={[4.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11325"
          geometry={nodes.Tile11325.geometry}
          material={materials.Material}
          position={[0.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11327"
          geometry={nodes.Tile11327.geometry}
          material={materials.Material}
          position={[1.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11328"
          geometry={nodes.Tile11328.geometry}
          material={materials.Material}
          position={[2, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11330"
          geometry={nodes.Tile11330.geometry}
          material={materials.Material}
          position={[-1.5, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11331"
          geometry={nodes.Tile11331.geometry}
          material={materials.Material}
          position={[-1, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11332"
          geometry={nodes.Tile11332.geometry}
          material={materials.Material}
          position={[-0.5, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11339"
          geometry={nodes.Tile11339.geometry}
          material={materials.Material}
          position={[-0.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11340"
          geometry={nodes.Tile11340.geometry}
          material={materials.Material}
          position={[-4.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11344"
          geometry={nodes.Tile11344.geometry}
          material={materials.Material}
          position={[-1, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11345"
          geometry={nodes.Tile11345.geometry}
          material={materials.Material}
          position={[-0.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11347"
          geometry={nodes.Tile11347.geometry}
          material={materials.Material}
          position={[-4, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11349"
          geometry={nodes.Tile11349.geometry}
          material={materials.Material}
          position={[-3, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11350"
          geometry={nodes.Tile11350.geometry}
          material={materials.Material}
          position={[-2.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11352"
          geometry={nodes.Tile11352.geometry}
          material={materials.Material}
          position={[1.5, 0, 7.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11353"
          geometry={nodes.Tile11353.geometry}
          material={materials.Material}
          position={[5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11354"
          geometry={nodes.Tile11354.geometry}
          material={materials.Material}
          position={[4.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11357"
          geometry={nodes.Tile11357.geometry}
          material={materials.Material}
          position={[-0.5, 0, 4.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11360"
          geometry={nodes.Tile11360.geometry}
          material={materials.Material}
          position={[-0.5, 0, 6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11365"
          geometry={nodes.Tile11365.geometry}
          material={materials.Material}
          position={[3.5, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11368"
          geometry={nodes.Tile11368.geometry}
          material={materials.Material}
          position={[0.5, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11378"
          geometry={nodes.Tile11378.geometry}
          material={materials.Material}
          position={[-0.5, 0, 7.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11381"
          geometry={nodes.Tile11381.geometry}
          material={materials.Material}
          position={[0, 0, 4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11382"
          geometry={nodes.Tile11382.geometry}
          material={materials.Material}
          position={[-0.5, 0, 4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11384"
          geometry={nodes.Tile11384.geometry}
          material={materials.Material}
          position={[0.5, 0, 8.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11386"
          geometry={nodes.Tile11386.geometry}
          material={materials.Material}
          position={[0.5, 0, 5.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11389"
          geometry={nodes.Tile11389.geometry}
          material={materials.Material}
          position={[2.5, 0, 4.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11390"
          geometry={nodes.Tile11390.geometry}
          material={materials.Material}
          position={[2.5, 0, 6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11392"
          geometry={nodes.Tile11392.geometry}
          material={materials.Material}
          position={[2.5, 0, 6]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11393"
          geometry={nodes.Tile11393.geometry}
          material={materials.Material}
          position={[2.5, 0, 7.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11398"
          geometry={nodes.Tile11398.geometry}
          material={materials.Material}
          position={[-1.5, 0, 4.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11399"
          geometry={nodes.Tile11399.geometry}
          material={materials.Material}
          position={[-1.5, 0, 6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11402"
          geometry={nodes.Tile11402.geometry}
          material={materials.Material}
          position={[-1.5, 0, 7.5]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11405"
          geometry={nodes.Tile11405.geometry}
          material={materials.Material}
          position={[-2, 0, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11407"
          geometry={nodes.Tile11407.geometry}
          material={materials.Material}
          position={[1.5, 0, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11441"
          geometry={nodes.Tile11441.geometry}
          material={materials.Material}
          position={[2, 1.5, 9.5]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
          scale={[8, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11442"
          geometry={nodes.Tile11442.geometry}
          material={materials.Material}
          position={[2, 4, 9.5]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
          scale={[8, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11494"
          geometry={nodes.Tile11494.geometry}
          material={materials.Material}
          position={[4, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10175"
          geometry={nodes.Tile10175.geometry}
          material={materials.Material}
          position={[-1.5, 4, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11020"
          geometry={nodes.Tile11020.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11039"
          geometry={nodes.Tile11039.geometry}
          material={materials.Material}
          position={[-1.5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11055"
          geometry={nodes.Tile11055.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10116"
          geometry={nodes.Tile10116.geometry}
          material={materials.Material}
          position={[-5, 2.5, -4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11075"
          geometry={nodes.Tile11075.geometry}
          material={materials.Material}
          position={[-5, 1, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile93172"
          geometry={nodes.Tile93172.geometry}
          material={materials.Material}
          position={[-0.5, 0.5, -2.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11076"
          geometry={nodes.Tile11076.geometry}
          material={materials.Material}
          position={[-3, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11084"
          geometry={nodes.Tile11084.geometry}
          material={materials.Material}
          position={[-4, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10152"
          geometry={nodes.Tile10152.geometry}
          material={materials.Material}
          position={[0, 3.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11090"
          geometry={nodes.Tile11090.geometry}
          material={materials.Material}
          position={[0.5, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11097"
          geometry={nodes.Tile11097.geometry}
          material={materials.Material}
          position={[1, 0.5, -3]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10195"
          geometry={nodes.Tile10195.geometry}
          material={materials.Material}
          position={[6, 3.5, -4.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10206"
          geometry={nodes.Tile10206.geometry}
          material={materials.Material}
          position={[4.5, 4, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11119"
          geometry={nodes.Tile11119.geometry}
          material={materials.Material}
          position={[2, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11170"
          geometry={nodes.Tile11170.geometry}
          material={materials.Material}
          position={[2.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11171"
          geometry={nodes.Tile11171.geometry}
          material={materials.Material}
          position={[3, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11172"
          geometry={nodes.Tile11172.geometry}
          material={materials.Material}
          position={[3.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11183"
          geometry={nodes.Tile11183.geometry}
          material={materials.Material}
          position={[4, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10227"
          geometry={nodes.Tile10227.geometry}
          material={materials.Material}
          position={[-3, 3, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10239"
          geometry={nodes.Tile10239.geometry}
          material={materials.Material}
          position={[3, 3, 8.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11159"
          geometry={nodes.Tile11159.geometry}
          material={materials.Material}
          position={[-0.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11169"
          geometry={nodes.Tile11169.geometry}
          material={materials.Material}
          position={[-3, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11178"
          geometry={nodes.Tile11178.geometry}
          material={materials.Material}
          position={[-0.5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11192"
          geometry={nodes.Tile11192.geometry}
          material={materials.Material}
          position={[-3, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11201"
          geometry={nodes.Tile11201.geometry}
          material={materials.Material}
          position={[-2.5, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11203"
          geometry={nodes.Tile11203.geometry}
          material={materials.Material}
          position={[1.5, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11207"
          geometry={nodes.Tile11207.geometry}
          material={materials.Material}
          position={[4, 0, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11208"
          geometry={nodes.Tile11208.geometry}
          material={materials.Material}
          position={[2.5, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11211"
          geometry={nodes.Tile11211.geometry}
          material={materials.Material}
          position={[-3, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11222"
          geometry={nodes.Tile11222.geometry}
          material={materials.Material}
          position={[1.5, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11231"
          geometry={nodes.Tile11231.geometry}
          material={materials.Material}
          position={[-3, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11236"
          geometry={nodes.Tile11236.geometry}
          material={materials.Material}
          position={[-0.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11241"
          geometry={nodes.Tile11241.geometry}
          material={materials.Material}
          position={[2, 0, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11242"
          geometry={nodes.Tile11242.geometry}
          material={materials.Material}
          position={[1.5, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11246"
          geometry={nodes.Tile11246.geometry}
          material={materials.Material}
          position={[4, 0, 1.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11269"
          geometry={nodes.Tile11269.geometry}
          material={materials.Material}
          position={[4, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11299"
          geometry={nodes.Tile11299.geometry}
          material={materials.Material}
          position={[0, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11311"
          geometry={nodes.Tile11311.geometry}
          material={materials.Material}
          position={[2.5, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11318"
          geometry={nodes.Tile11318.geometry}
          material={materials.Material}
          position={[2, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11319"
          geometry={nodes.Tile11319.geometry}
          material={materials.Material}
          position={[2.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11324"
          geometry={nodes.Tile11324.geometry}
          material={materials.Material}
          position={[0, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11333"
          geometry={nodes.Tile11333.geometry}
          material={materials.Material}
          position={[-4.5, 0, 4]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11336"
          geometry={nodes.Tile11336.geometry}
          material={materials.Material}
          position={[-2, 0, 3.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11346"
          geometry={nodes.Tile11346.geometry}
          material={materials.Material}
          position={[-4.5, 0, 3]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11367"
          geometry={nodes.Tile11367.geometry}
          material={materials.Material}
          position={[0, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11383"
          geometry={nodes.Tile11383.geometry}
          material={materials.Material}
          position={[0, 0, 8.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11385"
          geometry={nodes.Tile11385.geometry}
          material={materials.Material}
          position={[0, 0, 5.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11391"
          geometry={nodes.Tile11391.geometry}
          material={materials.Material}
          position={[2, 0, 6]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11395"
          geometry={nodes.Tile11395.geometry}
          material={materials.Material}
          position={[-2, 0, 5.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11464"
          geometry={nodes.Tile11464.geometry}
          material={materials.Material}
          position={[3, 1.5, 9]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11480"
          geometry={nodes.Tile11480.geometry}
          material={materials.Material}
          position={[-3, 3, 9]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Bed003"
          geometry={nodes.Bed003.geometry}
          material={materials.Material}
          position={[-3.5, 1.2, -2.8]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12046"
          geometry={nodes.Tile12046.geometry}
          material={materials.Material}
          position={[-5, 0.5, -1.5]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12057"
          geometry={nodes.Tile12057.geometry}
          material={materials.Material}
          position={[-1, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12058"
          geometry={nodes.Tile12058.geometry}
          material={materials.Material}
          position={[-1.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12059"
          geometry={nodes.Tile12059.geometry}
          material={materials.Material}
          position={[-2, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12060"
          geometry={nodes.Tile12060.geometry}
          material={materials.Material}
          position={[-2.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12049"
          geometry={nodes.Tile12049.geometry}
          material={materials.Material}
          position={[-5, 0.5, -1]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12047"
          geometry={nodes.Tile12047.geometry}
          material={materials.Material}
          position={[-4, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12050"
          geometry={nodes.Tile12050.geometry}
          material={materials.Material}
          position={[-2.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12048"
          geometry={nodes.Tile12048.geometry}
          material={materials.Material}
          position={[-5, 0.5, -2]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[4, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12052"
          geometry={nodes.Tile12052.geometry}
          material={materials.Material}
          position={[1.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12053"
          geometry={nodes.Tile12053.geometry}
          material={materials.Material}
          position={[1, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12054"
          geometry={nodes.Tile12054.geometry}
          material={materials.Material}
          position={[0.5, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12055"
          geometry={nodes.Tile12055.geometry}
          material={materials.Material}
          position={[0, 0, -0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12051"
          geometry={nodes.Tile12051.geometry}
          material={materials.Material}
          position={[3, 0, 4.5]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12056"
          geometry={nodes.Tile12056.geometry}
          material={materials.Material}
          position={[3, 0, 6]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12061"
          geometry={nodes.Tile12061.geometry}
          material={materials.Material}
          position={[3, 0, 6.5]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12062"
          geometry={nodes.Tile12062.geometry}
          material={materials.Material}
          position={[2.99, 0, 8.01]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12063"
          geometry={nodes.Tile12063.geometry}
          material={materials.Material}
          position={[-3, 0, 6.5]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12064"
          geometry={nodes.Tile12064.geometry}
          material={materials.Material}
          position={[-3, 0, 5]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12065"
          geometry={nodes.Tile12065.geometry}
          material={materials.Material}
          position={[-3, 0, 8.5]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile12066"
          geometry={nodes.Tile12066.geometry}
          material={materials.Material}
          position={[-3, 0, 7]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow4006"
          geometry={nodes.WIndow4006.geometry}
          material={materials.Material}
          position={[-4, 1, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow4008"
          geometry={nodes.WIndow4008.geometry}
          material={materials.Material}
          position={[5, 1, 2.5]}
          rotation={[Math.PI / 2, 0, 2.36]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow4009"
          geometry={nodes.WIndow4009.geometry}
          material={materials.Material}
          position={[-3.59, 1, 3.91]}
          rotation={[Math.PI / 2, 0, -2.36]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10112"
          geometry={nodes.Tile10112.geometry}
          material={materials.Material}
          position={[-4.5, 1.5, -4]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10113"
          geometry={nodes.Tile10113.geometry}
          material={materials.Material}
          position={[-5, 1.5, -4]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4002"
          geometry={nodes.Tile4002.geometry}
          material={materials.Material}
          position={[-5, 1.5, -4.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4003"
          geometry={nodes.Tile4003.geometry}
          material={materials.Material}
          position={[-3, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4004"
          geometry={nodes.Tile4004.geometry}
          material={materials.Material}
          position={[-3.5, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4005"
          geometry={nodes.Tile4005.geometry}
          material={materials.Material}
          position={[-4, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4006"
          geometry={nodes.Tile4006.geometry}
          material={materials.Material}
          position={[-5, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4007"
          geometry={nodes.Tile4007.geometry}
          material={materials.Material}
          position={[-4.5, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4008"
          geometry={nodes.Tile4008.geometry}
          material={materials.Material}
          position={[-5, 1, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4017"
          geometry={nodes.Tile4017.geometry}
          material={materials.Material}
          position={[0, 2.5, -0.5]}
          scale={[-1, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile10156"
          geometry={nodes.Tile10156.geometry}
          material={materials.Material}
          position={[1.5, 3.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4009"
          geometry={nodes.Tile4009.geometry}
          material={materials.Material}
          position={[3.5, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4010"
          geometry={nodes.Tile4010.geometry}
          material={materials.Material}
          position={[2.5, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile4011"
          geometry={nodes.Tile4011.geometry}
          material={materials.Material}
          position={[3, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11426"
          geometry={nodes.Tile11426.geometry}
          material={materials.Material}
          position={[0, 2, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11427"
          geometry={nodes.Tile11427.geometry}
          material={materials.Material}
          position={[-0.5, 2, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1, -1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11428"
          geometry={nodes.Tile11428.geometry}
          material={materials.Material}
          position={[-0.5, 2, 0]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11429"
          geometry={nodes.Tile11429.geometry}
          material={materials.Material}
          position={[-0.5, 2, -0.5]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11430"
          geometry={nodes.Tile11430.geometry}
          material={materials.Material}
          position={[0, 1.5, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11431"
          geometry={nodes.Tile11431.geometry}
          material={materials.Material}
          position={[-0.5, 1.5, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1, -1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11432"
          geometry={nodes.Tile11432.geometry}
          material={materials.Material}
          position={[-0.5, 1.5, 0]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11433"
          geometry={nodes.Tile11433.geometry}
          material={materials.Material}
          position={[-0.5, 1.5, -0.5]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11434"
          geometry={nodes.Tile11434.geometry}
          material={materials.Material}
          position={[0, 1, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11435"
          geometry={nodes.Tile11435.geometry}
          material={materials.Material}
          position={[-0.5, 1, -0.5]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1, -1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11436"
          geometry={nodes.Tile11436.geometry}
          material={materials.Material}
          position={[-0.5, 1, 0]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          scale={[1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11437"
          geometry={nodes.Tile11437.geometry}
          material={materials.Material}
          position={[-0.5, 1, -0.5]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11105"
          geometry={nodes.Tile11105.geometry}
          material={materials.Material}
          position={[-1.75, 0.5, -2.27]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11110"
          geometry={nodes.Tile11110.geometry}
          material={materials.Material}
          position={[1.75, 0.5, -2.77]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11152"
          geometry={nodes.Tile11152.geometry}
          material={materials.Material}
          position={[-3, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11153"
          geometry={nodes.Tile11153.geometry}
          material={materials.Material}
          position={[-3.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11156"
          geometry={nodes.Tile11156.geometry}
          material={materials.Material}
          position={[-4, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11160"
          geometry={nodes.Tile11160.geometry}
          material={materials.Material}
          position={[-1, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11161"
          geometry={nodes.Tile11161.geometry}
          material={materials.Material}
          position={[-1.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11162"
          geometry={nodes.Tile11162.geometry}
          material={materials.Material}
          position={[-2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11187"
          geometry={nodes.Tile11187.geometry}
          material={materials.Material}
          position={[1, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11188"
          geometry={nodes.Tile11188.geometry}
          material={materials.Material}
          position={[0.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11190"
          geometry={nodes.Tile11190.geometry}
          material={materials.Material}
          position={[3, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11191"
          geometry={nodes.Tile11191.geometry}
          material={materials.Material}
          position={[2.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11202"
          geometry={nodes.Tile11202.geometry}
          material={materials.Material}
          position={[1.5, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11232"
          geometry={nodes.Tile11232.geometry}
          material={materials.Material}
          position={[-3.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11233"
          geometry={nodes.Tile11233.geometry}
          material={materials.Material}
          position={[-4, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11234"
          geometry={nodes.Tile11234.geometry}
          material={materials.Material}
          position={[-4.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11237"
          geometry={nodes.Tile11237.geometry}
          material={materials.Material}
          position={[-1, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11238"
          geometry={nodes.Tile11238.geometry}
          material={materials.Material}
          position={[-1.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11239"
          geometry={nodes.Tile11239.geometry}
          material={materials.Material}
          position={[-2, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11240"
          geometry={nodes.Tile11240.geometry}
          material={materials.Material}
          position={[-2.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11252"
          geometry={nodes.Tile11252.geometry}
          material={materials.Material}
          position={[-4.5, 2, 1]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11256"
          geometry={nodes.Tile11256.geometry}
          material={materials.Material}
          position={[2, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11257"
          geometry={nodes.Tile11257.geometry}
          material={materials.Material}
          position={[1, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11258"
          geometry={nodes.Tile11258.geometry}
          material={materials.Material}
          position={[0.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11260"
          geometry={nodes.Tile11260.geometry}
          material={materials.Material}
          position={[4, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11261"
          geometry={nodes.Tile11261.geometry}
          material={materials.Material}
          position={[3.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11262"
          geometry={nodes.Tile11262.geometry}
          material={materials.Material}
          position={[3, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11268"
          geometry={nodes.Tile11268.geometry}
          material={materials.Material}
          position={[1.5, 0, 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11276"
          geometry={nodes.Tile11276.geometry}
          material={materials.Material}
          position={[4.5, 2, 1.51]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11363"
          geometry={nodes.Tile11363.geometry}
          material={materials.Material}
          position={[2.5, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11364"
          geometry={nodes.Tile11364.geometry}
          material={materials.Material}
          position={[3, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11369"
          geometry={nodes.Tile11369.geometry}
          material={materials.Material}
          position={[1, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11370"
          geometry={nodes.Tile11370.geometry}
          material={materials.Material}
          position={[1.5, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11371"
          geometry={nodes.Tile11371.geometry}
          material={materials.Material}
          position={[2, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11372"
          geometry={nodes.Tile11372.geometry}
          material={materials.Material}
          position={[-2, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11373"
          geometry={nodes.Tile11373.geometry}
          material={materials.Material}
          position={[-1.5, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11374"
          geometry={nodes.Tile11374.geometry}
          material={materials.Material}
          position={[-1, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11375"
          geometry={nodes.Tile11375.geometry}
          material={materials.Material}
          position={[-0.5, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11376"
          geometry={nodes.Tile11376.geometry}
          material={materials.Material}
          position={[-2.5, 0, 4.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11387"
          geometry={nodes.Tile11387.geometry}
          material={materials.Material}
          position={[0, 0, 8.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11388"
          geometry={nodes.Tile11388.geometry}
          material={materials.Material}
          position={[-0.5, 0, 8.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11406"
          geometry={nodes.Tile11406.geometry}
          material={materials.Material}
          position={[-0.25, 0, 1.23]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11410"
          geometry={nodes.Tile11410.geometry}
          material={materials.Material}
          position={[3, 1, 4.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11411"
          geometry={nodes.Tile11411.geometry}
          material={materials.Material}
          position={[3, 1, 5.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11412"
          geometry={nodes.Tile11412.geometry}
          material={materials.Material}
          position={[3, 1, 7]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11413"
          geometry={nodes.Tile11413.geometry}
          material={materials.Material}
          position={[3, 1, 8]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11414"
          geometry={nodes.Tile11414.geometry}
          material={materials.Material}
          position={[-3, 1, 5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11415"
          geometry={nodes.Tile11415.geometry}
          material={materials.Material}
          position={[-3, 1, 6]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11416"
          geometry={nodes.Tile11416.geometry}
          material={materials.Material}
          position={[-3, 1, 7.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11417"
          geometry={nodes.Tile11417.geometry}
          material={materials.Material}
          position={[-3, 1, 8.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11452"
          geometry={nodes.Tile11452.geometry}
          material={materials.Material}
          position={[-3, 1, 9]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11457"
          geometry={nodes.Tile11457.geometry}
          material={materials.Material}
          position={[3, 1, 9]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11462"
          geometry={nodes.Tile11462.geometry}
          material={materials.Material}
          position={[3, 2, 9.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11467"
          geometry={nodes.Tile11467.geometry}
          material={materials.Material}
          position={[3, 3, 9.5]}
          rotation={[Math.PI, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11471"
          geometry={nodes.Tile11471.geometry}
          material={materials.Material}
          position={[3, 3, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11472"
          geometry={nodes.Tile11472.geometry}
          material={materials.Material}
          position={[3, 2.5, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11474"
          geometry={nodes.Tile11474.geometry}
          material={materials.Material}
          position={[-2.5, 2, 9.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11479"
          geometry={nodes.Tile11479.geometry}
          material={materials.Material}
          position={[-2.5, 3, 9.5]}
          rotation={[Math.PI, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11483"
          geometry={nodes.Tile11483.geometry}
          material={materials.Material}
          position={[-2.5, 3, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11484"
          geometry={nodes.Tile11484.geometry}
          material={materials.Material}
          position={[-2.5, 2.5, 9.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11485"
          geometry={nodes.Tile11485.geometry}
          material={materials.Material}
          position={[3, 3, 9]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11486"
          geometry={nodes.Tile11486.geometry}
          material={materials.Material}
          position={[3, 2.5, 9]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11487"
          geometry={nodes.Tile11487.geometry}
          material={materials.Material}
          position={[-3, 3, 9.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11488"
          geometry={nodes.Tile11488.geometry}
          material={materials.Material}
          position={[-3, 2.5, 9.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Plant3004"
          geometry={nodes.Plant3004.geometry}
          material={materials.Material}
          position={[-4.74, 1.52, -4.26]}
          rotation={[0, Math.PI / 9, 0]}
          scale={0.66}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Plant3005"
          geometry={nodes.Plant3005.geometry}
          material={materials.Material}
          position={[4.06, 1, 2.99]}
          rotation={[0, Math.PI / 4, 0]}
          scale={0.7}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Plant3006"
          geometry={nodes.Plant3006.geometry}
          material={materials.Material}
          position={[-0.04, 1, 5.49]}
          rotation={[0, Math.PI / 4, 0]}
          scale={0.6}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Plant3007"
          geometry={nodes.Plant3007.geometry}
          material={materials.Material}
          position={[-0.04, 1, 7.49]}
          rotation={[0, -0.26, 0]}
          scale={0.6}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe11009"
          geometry={nodes.Pipe11009.geometry}
          material={materials.Material}
          position={[-4.36, 0.81, -4.89]}
          rotation={[-Math.PI, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13061"
          geometry={nodes.Pipe13061.geometry}
          material={materials.Material}
          position={[-4.36, 2.01, -4.89]}
          rotation={[-Math.PI, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe11015"
          geometry={nodes.Pipe11015.geometry}
          material={materials.Material}
          position={[-4.87, 0.91, 0.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe11016"
          geometry={nodes.Pipe11016.geometry}
          material={materials.Material}
          position={[-4.87, 0.91, 2.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13072"
          geometry={nodes.Pipe13072.geometry}
          material={materials.Material}
          position={[-4.87, 2.71, 2.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13073"
          geometry={nodes.Pipe13073.geometry}
          material={materials.Material}
          position={[-4.87, 2.71, 0.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe11014"
          geometry={nodes.Pipe11014.geometry}
          material={materials.Material}
          position={[4.87, 0.91, 2.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe11017"
          geometry={nodes.Pipe11017.geometry}
          material={materials.Material}
          position={[4.87, 0.91, 0.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13076"
          geometry={nodes.Pipe13076.geometry}
          material={materials.Material}
          position={[4.87, 2.71, 0.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13077"
          geometry={nodes.Pipe13077.geometry}
          material={materials.Material}
          position={[4.87, 2.71, 2.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14010"
          geometry={nodes.Pipe14010.geometry}
          material={materials.Material}
          position={[-4.36, 1.41, -4.89]}
          rotation={[-Math.PI, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14015"
          geometry={nodes.Pipe14015.geometry}
          material={materials.Material}
          position={[-4.87, 2.11, 0.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14016"
          geometry={nodes.Pipe14016.geometry}
          material={materials.Material}
          position={[-4.87, 1.51, 0.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14017"
          geometry={nodes.Pipe14017.geometry}
          material={materials.Material}
          position={[-4.87, 1.51, 2.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14018"
          geometry={nodes.Pipe14018.geometry}
          material={materials.Material}
          position={[-4.87, 2.11, 2.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14019"
          geometry={nodes.Pipe14019.geometry}
          material={materials.Material}
          position={[4.87, 2.11, 2.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14020"
          geometry={nodes.Pipe14020.geometry}
          material={materials.Material}
          position={[4.87, 1.51, 2.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14021"
          geometry={nodes.Pipe14021.geometry}
          material={materials.Material}
          position={[4.87, 1.51, 0.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe14022"
          geometry={nodes.Pipe14022.geometry}
          material={materials.Material}
          position={[4.87, 2.11, 0.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Shape043"
          geometry={nodes.Shape043.geometry}
          material={materials.Material}
          position={[-4.75, 2.5, -4.25]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Shape041"
          geometry={nodes.Shape041.geometry}
          material={materials.Material}
          position={[-4.75, 1.5, -1.25]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Shape042"
          geometry={nodes.Shape042.geometry}
          material={materials.Material}
          position={[-4.75, 1.5, -0.75]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Shape044"
          geometry={nodes.Shape044.geometry}
          material={materials.Material}
          position={[4.75, 3, -5.25]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Shape045"
          geometry={nodes.Shape045.geometry}
          material={materials.Material}
          position={[2.25, 1.5, 9.25]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Shape046"
          geometry={nodes.Shape046.geometry}
          material={materials.Material}
          position={[2.25, 3, 9.25]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Shape047"
          geometry={nodes.Shape047.geometry}
          material={materials.Material}
          position={[-2.25, 1.5, 9.25]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Shape048"
          geometry={nodes.Shape048.geometry}
          material={materials.Material}
          position={[-2.25, 3, 9.25]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation1010"
          geometry={nodes.Ventilation1010.geometry}
          material={materials.Material}
          position={[-1.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation1011"
          geometry={nodes.Ventilation1011.geometry}
          material={materials.Material}
          position={[0.5, 1, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation1012"
          geometry={nodes.Ventilation1012.geometry}
          material={materials.Material}
          position={[-0.5, -1.5, 9.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation1013"
          geometry={nodes.Ventilation1013.geometry}
          material={materials.Material}
          position={[1.5, -1.5, 9.5]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12010"
          geometry={nodes.Pipe12010.geometry}
          material={materials.Material}
          position={[-4.36, 3.01, -4.89]}
          rotation={[-Math.PI, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12009"
          geometry={nodes.Pipe12009.geometry}
          material={materials.Material}
          position={[-3.3, 3.86, -4.88]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12011"
          geometry={nodes.Pipe12011.geometry}
          material={materials.Material}
          position={[-4.75, 3.71, 0.25]}
          rotation={[Math.PI, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12012"
          geometry={nodes.Pipe12012.geometry}
          material={materials.Material}
          position={[-4.99, 3.71, 2.25]}
          rotation={[-Math.PI, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12013"
          geometry={nodes.Pipe12013.geometry}
          material={materials.Material}
          position={[1.76, 3.62, -4.88]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12014"
          geometry={nodes.Pipe12014.geometry}
          material={materials.Material}
          position={[4.75, 3.71, 2.24]}
          rotation={[-Math.PI, 1.57, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12015"
          geometry={nodes.Pipe12015.geometry}
          material={materials.Material}
          position={[4.99, 3.71, 0.24]}
          rotation={[Math.PI, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12016"
          geometry={nodes.Pipe12016.geometry}
          material={materials.Material}
          position={[1.89, 3.88, -2.58]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12017"
          geometry={nodes.Pipe12017.geometry}
          material={materials.Material}
          position={[2.13, 3.88, 2.49]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12018"
          geometry={nodes.Pipe12018.geometry}
          material={materials.Material}
          position={[-0.11, 3.88, -3.65]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12019"
          geometry={nodes.Pipe12019.geometry}
          material={materials.Material}
          position={[0.13, 3.88, 1.41]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12020"
          geometry={nodes.Pipe12020.geometry}
          material={materials.Material}
          position={[-0.11, 3.88, 2.35]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12021"
          geometry={nodes.Pipe12021.geometry}
          material={materials.Material}
          position={[0.13, 3.88, 7.41]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12022"
          geometry={nodes.Pipe12022.geometry}
          material={materials.Material}
          position={[-2.5, 3.88, 4.32]}
          rotation={[Math.PI, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12023"
          geometry={nodes.Pipe12023.geometry}
          material={materials.Material}
          position={[2.56, 3.88, 4.08]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12024"
          geometry={nodes.Pipe12024.geometry}
          material={materials.Material}
          position={[-2.11, 3.88, -2.58]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe12025"
          geometry={nodes.Pipe12025.geometry}
          material={materials.Material}
          position={[-1.87, 3.88, 2.49]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13062"
          geometry={nodes.Pipe13062.geometry}
          material={materials.Material}
          position={[-4.36, 2.61, -4.89]}
          rotation={[-Math.PI, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13064"
          geometry={nodes.Pipe13064.geometry}
          material={materials.Material}
          position={[-4.87, 3.59, 1.25]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[1, 3, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13066"
          geometry={nodes.Pipe13066.geometry}
          material={materials.Material}
          position={[-4.87, 3.31, 0.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13067"
          geometry={nodes.Pipe13067.geometry}
          material={materials.Material}
          position={[-4.87, 0.31, 0.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13069"
          geometry={nodes.Pipe13069.geometry}
          material={materials.Material}
          position={[-4.87, 3.31, 2.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13070"
          geometry={nodes.Pipe13070.geometry}
          material={materials.Material}
          position={[-4.87, 0.31, 2.37]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13074"
          geometry={nodes.Pipe13074.geometry}
          material={materials.Material}
          position={[-0.77, 3.74, -5]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[1, 8.09, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13063"
          geometry={nodes.Pipe13063.geometry}
          material={materials.Material}
          position={[4.87, 3.59, 1.24]}
          rotation={[-Math.PI / 2, 0, -Math.PI]}
          scale={[1, 3, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13065"
          geometry={nodes.Pipe13065.geometry}
          material={materials.Material}
          position={[4.87, 3.31, 2.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13068"
          geometry={nodes.Pipe13068.geometry}
          material={materials.Material}
          position={[4.87, 0.31, 2.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13071"
          geometry={nodes.Pipe13071.geometry}
          material={materials.Material}
          position={[4.87, 3.31, 0.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13075"
          geometry={nodes.Pipe13075.geometry}
          material={materials.Material}
          position={[4.87, 0.31, 0.12]}
          rotation={[0, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13078"
          geometry={nodes.Pipe13078.geometry}
          material={materials.Material}
          position={[2.01, 4, -0.04]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1, 8.09, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13079"
          geometry={nodes.Pipe13079.geometry}
          material={materials.Material}
          position={[0.01, 4, -1.12]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1, 8.09, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13080"
          geometry={nodes.Pipe13080.geometry}
          material={materials.Material}
          position={[0.01, 4, 4.88]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1, 8.09, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13081"
          geometry={nodes.Pipe13081.geometry}
          material={materials.Material}
          position={[0.03, 4, 4.2]}
          rotation={[Math.PI / 2, 0, 1.57]}
          scale={[1, 8.09, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe13082"
          geometry={nodes.Pipe13082.geometry}
          material={materials.Material}
          position={[-1.99, 4, -0.04]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1, 8.09, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2002"
          geometry={nodes.Ventilation2002.geometry}
          material={materials.Material}
          position={[-5, 2.5, -0.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2015"
          geometry={nodes.Ventilation2015.geometry}
          material={materials.Material}
          position={[-5, 0, -0.7]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2016"
          geometry={nodes.Ventilation2016.geometry}
          material={materials.Material}
          position={[2, 0.3, -3]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2017"
          geometry={nodes.Ventilation2017.geometry}
          material={materials.Material}
          position={[3, 0.5, -5.7]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2018"
          geometry={nodes.Ventilation2018.geometry}
          material={materials.Material}
          position={[3, 3, -5.7]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2019"
          geometry={nodes.Ventilation2019.geometry}
          material={materials.Material}
          position={[3, 0.3, -2]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2020"
          geometry={nodes.Ventilation2020.geometry}
          material={materials.Material}
          position={[5, 0.3, -1]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2021"
          geometry={nodes.Ventilation2021.geometry}
          material={materials.Material}
          position={[-3.5, 3, -5.2]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2022"
          geometry={nodes.Ventilation2022.geometry}
          material={materials.Material}
          position={[3, 0, -0.7]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2023"
          geometry={nodes.Ventilation2023.geometry}
          material={materials.Material}
          position={[5, 0.3, -4]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2024"
          geometry={nodes.Ventilation2024.geometry}
          material={materials.Material}
          position={[6.2, 3, -3.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2025"
          geometry={nodes.Ventilation2025.geometry}
          material={materials.Material}
          position={[5.01, 2, -0.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.5, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2026"
          geometry={nodes.Ventilation2026.geometry}
          material={materials.Material}
          position={[5.01, 1, -0.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.5, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2027"
          geometry={nodes.Ventilation2027.geometry}
          material={materials.Material}
          position={[5.01, 0, -0.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.5, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2028"
          geometry={nodes.Ventilation2028.geometry}
          material={materials.Material}
          position={[5.01, 3, -0.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.5, 0.2, 0.5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2029"
          geometry={nodes.Ventilation2029.geometry}
          material={materials.Material}
          position={[-3.6, 4, 6]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2030"
          geometry={nodes.Ventilation2030.geometry}
          material={materials.Material}
          position={[3.6, 4, 7]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2031"
          geometry={nodes.Ventilation2031.geometry}
          material={materials.Material}
          position={[0.5, 0, 10.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2032"
          geometry={nodes.Ventilation2032.geometry}
          material={materials.Material}
          position={[2.5, 0, 10]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation2033"
          geometry={nodes.Ventilation2033.geometry}
          material={materials.Material}
          position={[-1.5, 0, 10]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation3020"
          geometry={nodes.Ventilation3020.geometry}
          material={materials.Material}
          position={[-3.5, 0.5, -5]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation3023"
          geometry={nodes.Ventilation3023.geometry}
          material={materials.Material}
          position={[6, 0.5, -4]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[2, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation3021"
          geometry={nodes.Ventilation3021.geometry}
          material={materials.Material}
          position={[3, 0, 5]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation3022"
          geometry={nodes.Ventilation3022.geometry}
          material={materials.Material}
          position={[3, 0, 7]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation3024"
          geometry={nodes.Ventilation3024.geometry}
          material={materials.Material}
          position={[-3, 0, 8]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Ventilation3025"
          geometry={nodes.Ventilation3025.geometry}
          material={materials.Material}
          position={[-3, 0, 6]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="FishTank003"
          geometry={nodes.FishTank003.geometry}
          material={materials.Material}
          position={[-3.5, 1, -0.8]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="FishTank004"
          geometry={nodes.FishTank004.geometry}
          material={materials.Material}
          position={[-4.14, 1, 3.05]}
          rotation={[0, -Math.PI / 4, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe21004"
          geometry={nodes.Pipe21004.geometry}
          material={materials.Material}
          position={[-4.98, 2.87, -0.25]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe21005"
          geometry={nodes.Pipe21005.geometry}
          material={materials.Material}
          position={[-4.98, 1.07, -0.25]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe21006"
          geometry={nodes.Pipe21006.geometry}
          material={materials.Material}
          position={[-0.23, 2.87, -4.99]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe21007"
          geometry={nodes.Pipe21007.geometry}
          material={materials.Material}
          position={[-0.23, 1.07, -4.99]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe21008"
          geometry={nodes.Pipe21008.geometry}
          material={materials.Material}
          position={[-2.98, 3.3, 7.85]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe21009"
          geometry={nodes.Pipe21009.geometry}
          material={materials.Material}
          position={[2.82, 3.3, 7.85]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe22006"
          geometry={nodes.Pipe22006.geometry}
          material={materials.Material}
          position={[-4.98, 3.27, -0.25]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe22007"
          geometry={nodes.Pipe22007.geometry}
          material={materials.Material}
          position={[-4.98, 0.67, -0.25]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe22008"
          geometry={nodes.Pipe22008.geometry}
          material={materials.Material}
          position={[-0.23, 3.27, -4.85]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe22009"
          geometry={nodes.Pipe22009.geometry}
          material={materials.Material}
          position={[-1.03, 3.27, -4.85]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe22010"
          geometry={nodes.Pipe22010.geometry}
          material={materials.Material}
          position={[-2.98, 3.3, 8.25]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe22011"
          geometry={nodes.Pipe22011.geometry}
          material={materials.Material}
          position={[2.82, 3.3, 8.25]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23009"
          geometry={nodes.Pipe23009.geometry}
          material={materials.Material}
          position={[-4.96, 2.27, -0.25]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23010"
          geometry={nodes.Pipe23010.geometry}
          material={materials.Material}
          position={[-4.96, 3.27, -0.65]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23011"
          geometry={nodes.Pipe23011.geometry}
          material={materials.Material}
          position={[-4.96, 1.67, -0.25]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23012"
          geometry={nodes.Pipe23012.geometry}
          material={materials.Material}
          position={[-4.96, 0.67, -0.65]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23013"
          geometry={nodes.Pipe23013.geometry}
          material={materials.Material}
          position={[-0.23, 2.27, -4.97]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23014"
          geometry={nodes.Pipe23014.geometry}
          material={materials.Material}
          position={[-0.23, 1.67, -4.97]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23015"
          geometry={nodes.Pipe23015.geometry}
          material={materials.Material}
          position={[-0.23, 0.47, -4.97]}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23016"
          geometry={nodes.Pipe23016.geometry}
          material={materials.Material}
          position={[-0.63, 3.27, -4.97]}
          rotation={[-Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23017"
          geometry={nodes.Pipe23017.geometry}
          material={materials.Material}
          position={[-1.03, 2.87, -4.97]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23018"
          geometry={nodes.Pipe23018.geometry}
          material={materials.Material}
          position={[-2.96, 3.3, 7.25]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23019"
          geometry={nodes.Pipe23019.geometry}
          material={materials.Material}
          position={[-2.96, 3.7, 8.25]}
          rotation={[-Math.PI, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23020"
          geometry={nodes.Pipe23020.geometry}
          material={materials.Material}
          position={[2.84, 3.3, 7.25]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pipe23021"
          geometry={nodes.Pipe23021.geometry}
          material={materials.Material}
          position={[2.84, 3.7, 8.25]}
          rotation={[-Math.PI, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Panel1009"
          geometry={nodes.Panel1009.geometry}
          material={materials.Material}
          position={[-3, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Crate1005"
          geometry={nodes.Crate1005.geometry}
          material={materials.Material}
          position={[0.01, 0.5, -4.46]}
          rotation={[0, -1.31, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Crate1006"
          geometry={nodes.Crate1006.geometry}
          material={materials.Material}
          position={[0.01, 0, 4.44]}
          rotation={[0, -1.31, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="ArcadeBlue004"
          geometry={nodes.ArcadeBlue004.geometry}
          material={materials.Material}
          position={[6, 0.5, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="ArcadeRed002"
          geometry={nodes.ArcadeRed002.geometry}
          material={materials.Material}
          position={[6, 0.5, -2]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow1004"
          geometry={nodes.WIndow1004.geometry}
          material={materials.Material}
          position={[6, 1, -4]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Handle11002"
          geometry={nodes.Handle11002.geometry}
          material={materials.Material}
          position={[3.25, 0.5, -2.73]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Handle11016"
          geometry={nodes.Handle11016.geometry}
          material={materials.Material}
          position={[5.25, 0.5, -2.73]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Handle11017"
          geometry={nodes.Handle11017.geometry}
          material={materials.Material}
          position={[3.25, 0.5, -3.23]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Handle11018"
          geometry={nodes.Handle11018.geometry}
          material={materials.Material}
          position={[5.25, 0.5, -3.23]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column058"
          geometry={nodes.Column058.geometry}
          material={materials.Material}
          position={[2, 0.5, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column059"
          geometry={nodes.Column059.geometry}
          material={materials.Material}
          position={[6, 0.5, -5.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column060"
          geometry={nodes.Column060.geometry}
          material={materials.Material}
          position={[6, 0.5, -0.5]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column061"
          geometry={nodes.Column061.geometry}
          material={materials.Material}
          position={[3.59, 0, 4.41]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1, 1, 6]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column062"
          geometry={nodes.Column062.geometry}
          material={materials.Material}
          position={[3.59, 3, 4.41]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column063"
          geometry={nodes.Column063.geometry}
          material={materials.Material}
          position={[3.59, 3.5, 4.41]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column064"
          geometry={nodes.Column064.geometry}
          material={materials.Material}
          position={[-3.59, 0, 4.41]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1, 1, 6]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column065"
          geometry={nodes.Column065.geometry}
          material={materials.Material}
          position={[-3.59, 3, 4.41]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column066"
          geometry={nodes.Column066.geometry}
          material={materials.Material}
          position={[-3.59, 3.5, 4.41]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column056"
          geometry={nodes.Column056.geometry}
          material={materials.Material}
          position={[5, 0, 1.51]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column057"
          geometry={nodes.Column057.geometry}
          material={materials.Material}
          position={[5, 0, 1.01]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column068"
          geometry={nodes.Column068.geometry}
          material={materials.Material}
          position={[-5, 0, 1]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Column069"
          geometry={nodes.Column069.geometry}
          material={materials.Material}
          position={[-5, 0, 1.5]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 1, 7]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1003"
          geometry={nodes.Table1003.geometry}
          material={materials.Material}
          position={[-4.28, 0, 3.22]}
          rotation={[0, -Math.PI / 4, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1006"
          geometry={nodes.Table1006.geometry}
          material={materials.Material}
          position={[-4.28, 0, 3.22]}
          rotation={[0, -Math.PI / 4, 0]}
          scale={[-1, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1007"
          geometry={nodes.Table1007.geometry}
          material={materials.Material}
          position={[4.29, 0, 3.22]}
          rotation={[0, Math.PI / 4, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1010"
          geometry={nodes.Table1010.geometry}
          material={materials.Material}
          position={[4.29, 0, 3.22]}
          rotation={[0, Math.PI / 4, 0]}
          scale={[-1, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1005"
          geometry={nodes.Table1005.geometry}
          material={materials.Material}
          position={[0, 0, 5.46]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1009"
          geometry={nodes.Table1009.geometry}
          material={materials.Material}
          position={[0, 0, 7.46]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[-1, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1012"
          geometry={nodes.Table1012.geometry}
          material={materials.Material}
          position={[0, 0, 5.46]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1014"
          geometry={nodes.Table1014.geometry}
          material={materials.Material}
          position={[0, 0, 7.46]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[-1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1004"
          geometry={nodes.Table1004.geometry}
          material={materials.Material}
          position={[0, 0, 6.46]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1008"
          geometry={nodes.Table1008.geometry}
          material={materials.Material}
          position={[0, 0, 6.46]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[-1, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1011"
          geometry={nodes.Table1011.geometry}
          material={materials.Material}
          position={[0, 0, 6.46]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Table1013"
          geometry={nodes.Table1013.geometry}
          material={materials.Material}
          position={[0, 0, 6.46]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[-1, 1, -1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pot007"
          geometry={nodes.Pot007.geometry}
          material={materials.Material}
          position={[3.9, 0, -0.4]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow3006"
          geometry={nodes.WIndow3006.geometry}
          material={materials.Material}
          position={[-3.01, 1, 6.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[-1, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow3007"
          geometry={nodes.WIndow3007.geometry}
          material={materials.Material}
          position={[3.01, 1, 6.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow3008"
          geometry={nodes.WIndow3008.geometry}
          material={materials.Material}
          position={[-3.01, 1, 6.5]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow3009"
          geometry={nodes.WIndow3009.geometry}
          material={materials.Material}
          position={[3.01, 1, 6.5]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[-1, 1, 1]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Chair010"
          geometry={nodes.Chair010.geometry}
          material={materials.Material}
          position={[1.5, 0.01, 7.09]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Chair011"
          geometry={nodes.Chair011.geometry}
          material={materials.Material}
          position={[1.5, 0.01, 5.89]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Chair012"
          geometry={nodes.Chair012.geometry}
          material={materials.Material}
          position={[-1.5, 0.01, 5.89]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Chair013"
          geometry={nodes.Chair013.geometry}
          material={materials.Material}
          position={[-1.5, 0.01, 7.09]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pot2007"
          geometry={nodes.Pot2007.geometry}
          material={materials.Material}
          position={[2.75, 1.02, 8.74]}
          rotation={[0, 0.61, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Pot2008"
          geometry={nodes.Pot2008.geometry}
          material={materials.Material}
          position={[-2.75, 1.02, 8.74]}
          rotation={[0, -Math.PI / 6, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Tile11490"
          geometry={nodes.Tile11490.geometry}
          material={materials.Material}
          position={[-6, 4, 10.01]}
          rotation={[-Math.PI, 0, 0]}
          scale={[4, 1, 3.9]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp1021"
          geometry={nodes.Lamp1021.geometry}
          material={materials.Material}
          position={[-3, 3.95, -2.7]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp1001"
          geometry={nodes.Lamp1001.geometry}
          material={materials.Material}
          position={[-1, 3.95, 3.2]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2021"
          geometry={nodes.Lamp2021.geometry}
          material={materials.Material}
          position={[-1.75, 2.75, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2022"
          geometry={nodes.Lamp2022.geometry}
          material={materials.Material}
          position={[-5, 3.25, -2.26]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2023"
          geometry={nodes.Lamp2023.geometry}
          material={materials.Material}
          position={[1.75, 2.75, -5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2024"
          geometry={nodes.Lamp2024.geometry}
          material={materials.Material}
          position={[4.5, 3.25, 1.27]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2025"
          geometry={nodes.Lamp2025.geometry}
          material={materials.Material}
          position={[-4.5, 3.25, 1.24]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2026"
          geometry={nodes.Lamp2026.geometry}
          material={materials.Material}
          position={[-2.25, 2.75, 9.49]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2027"
          geometry={nodes.Lamp2027.geometry}
          material={materials.Material}
          position={[-2.25, 2.25, 9.49]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2028"
          geometry={nodes.Lamp2028.geometry}
          material={materials.Material}
          position={[2.25, 2.75, 9.49]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2029"
          geometry={nodes.Lamp2029.geometry}
          material={materials.Material}
          position={[2.25, 2.25, 9.49]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2030"
          geometry={nodes.Lamp2030.geometry}
          material={materials.Material}
          position={[-2.25, 3.75, 9.49]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2031"
          geometry={nodes.Lamp2031.geometry}
          material={materials.Material}
          position={[2.25, 3.75, 9.49]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2032"
          geometry={nodes.Lamp2032.geometry}
          material={materials.Material}
          position={[-2.25, 1.25, 9.49]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="Lamp2033"
          geometry={nodes.Lamp2033.geometry}
          material={materials.Material}
          position={[2.25, 1.25, 9.49]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow2009"
          geometry={nodes.WIndow2009.geometry}
          material={materials.Material}
          position={[2.5, 1, -5.5]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow2010"
          geometry={nodes.WIndow2010.geometry}
          material={materials.Material}
          position={[-5, 1, -2]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow2_Helix006"
          geometry={nodes.WIndow2_Helix006.geometry}
          material={materials.Material}
          position={[3.5, 2, -5.7]}
          rotation={[Math.PI / 2, 1.33, Math.PI / 2]}
        />
        <mesh
          
          
          material-toneMapped={false}
          material-emissiveIntensity={10}
          name="WIndow2_Helix007"
          geometry={nodes.WIndow2_Helix007.geometry}
          material={materials.Material}
          position={[-5.2, 2, -3]}
          rotation={[2.9, 0, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/cyberpunk-transformed.glb");
