import "./App.css";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import Scene from "./components/Scene";
import { Stats } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import Scrollbar, { ScrollbarPlugin } from "smooth-scrollbar";
import { isMobile } from "react-device-detect";
import { useProgress } from "@react-three/drei";
import gsap from "gsap";
import { Spinner } from "react-bootstrap";
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = "modal";

  static defaultOptions = {
    open: false,
  };

  transformDelta(delta) {
    return this.options.open ? { x: 0, y: 0 } : delta;
  }
}
function App() {
  const [scrollBar, setScrollBar] = useState(null);
  const [orientation, setOrientation] = useState("");
  const [aspect, setAspect] = useState(2);
  const [loading, setLoading] = useState(true);
  const { active, progress, errors, item, loaded, total } = useProgress();

  /**
   *
   *
   *
   * Aca van las ref
   *
   *
   *
   */
  const refDivCanvas = useRef(null);
  const refDivTamanio = useRef(null);
  const refDivRotate = useRef(null);
  const refDivHeight = useRef(null);
  const refScrollbar = useRef(null);

  useEffect(() => {
    // console.log("item", item);
    // console.log("loaded", loaded);
    console.log("total", total);
    //34 el total
    // console.log("progress", progress);
    if (loaded === 15) {
      setLoading(false);
    }
    // if (parseInt(loaded) === 35) {
    //   setLoading(false);
    // }
  }, [item, loaded, total, progress]);
  useEffect(() => {
    const overscrollOptions = {
      enable: true,
      effect: "bounce",
      damping: 0.15,
      // maxOverscroll: 150,
      // glowColor: "#fff",
    };
    const options = {
      disabled: true,
      alwaysShowTracks: true,
      continuousScrolling: false,
      damping: 0.03,
      plugins: {
        overscroll: { ...overscrollOptions },
      },
    };
    Scrollbar.use(ModalPlugin /* OverscrollPlugin */);

    Scrollbar.detachStyle();
    const scrollbar = Scrollbar.init(document.body, options);

    setScrollBar(scrollbar);
    refScrollbar.current = scrollbar;
    scrollbar.addListener(function (status) {
      const offset = status.offset;

      refDivCanvas.current.style.top = offset.y + "px";
      refDivCanvas.current.style.left = offset.x + "px";
      if (!refDivRotate.current) return;
      refDivRotate.current.style.top = offset.y + "px";
      refDivRotate.current.style.left = offset.x + "px";
    });
  }, []);

  useEffect(() => {
    let portrait = window.matchMedia("(orientation: portrait)");
    if (portrait.matches) {
      // console.log("portrait");
      setOrientation("portrait");
    } else {
      // console.log("landscape");
      setOrientation("landscape");
    }
    const funcionChangeOrientation = () => {
      // console.log("dispositivo es", dispositivo);
      if (!isMobile) return;
      let portrait = window.matchMedia("(orientation: portrait)");
      const width = refDivTamanio.current.clientWidth;
      const height = refDivTamanio.current.clientHeight;
      // if (isAndroid) {
      //   setAspect(window.innerWidth / window.innerHeight);
      // } else if (isIOS) {
      setAspect(width / height);
      // } else {
      //   setAspect(width / height);
      // }
      //Landscape es horizontal
      if (portrait.matches) {
        // Portrait mode
        // console.log("portrait");
        setOrientation("portrait");
      } else {
        // Landscape
        // console.log("landscape");
        setOrientation("landscape");
      }
    };

    portrait.addEventListener("change", funcionChangeOrientation);
  }, []);
  useEffect(() => {
    // console.log("body height", document.body.clientHeight);
    // const height = document.body.clientHeight;
    // const width = document.body.clientWidth;
    // refDivCanvas.current.style.height = height + "px";
    // refDivCanvas.current.style.width = width + "px";
    const funcionResize = () => {
      const height = refDivTamanio.current.clientHeight;
      const width = refDivTamanio.current.clientWidth;
      const aspect = width / height;
      // refDivCanvas.current.style.aspectRatio = aspect;
      // refDivHeight.current.style.aspectRatio = aspect;
      window.scrollTo({ top: 0, behavior: "smooth" });
      gsap.to(refScrollbar.current, {
        duration: 0.75,
        scrollTo: 70,
        ease: "none",
      });
      console.log("se ejecuta");
      // document.body.style.aspectRatio = aspect;
      // document.body.style.height = "100px";
      // setTimeout(() => {
      //   document.body.style.height = "100vh";
      // }, 1000);
    };
    window.addEventListener("resize", funcionResize);
    // funcionResize();
  }, []);
  return (
    <div>
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100vh",
            zIndex: "24",
            position: "fixed",
            background: "#FFFFFF",
            width: "100%",
          }}
        >
          <Spinner />
        </div>
      )}
      {/* {orientation === "portrait" && isMobile && !loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100vh",
            zIndex: "20",
            position: "fixed",
            background: "#FFFFFF",
            width: "100%",
          }}
          ref={refDivRotate}
        >
          <div className="text-center">
            <p>Please rotate the mobile</p>
          </div>
        </div>
      )} */}
      <div id="div-tamanio" ref={refDivTamanio} />
      <div style={{ position: "relative" }}>
        <div className="div-height" ref={refDivHeight}></div>
        <div id="div-canvas" ref={refDivCanvas}>
          <Canvas>
            <Scene scrollbar={scrollBar} />
            {/* <Stats /> */}
          </Canvas>
        </div>
        {/* <span id="scrollProgress" ref={refDivNumber}></span> */}
      </div>
    </div>
  );
}

export default App;
