/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 button.glb --transform
Author: DrakonSlayer (https://sketchfab.com/DrakonSlayer)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/button-dfff87207b394ded97a7c7c2669a5fe1
Title: Button
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
export function Button(props) {
  const { nodes, materials } = useGLTF(
    "./models/button/button-transformed.glb"
  );
  return (
    <group
      {...props}
      dispose={null}
      onPointerOver={() => {
        document.body.style.cursor = "pointer";
      }}
      onPointerLeave={() => {
        document.body.style.cursor = "default";
      }}
    >
      {/* <mesh geometry={nodes.Object_4.geometry} material={materials.Base} />
      <mesh geometry={nodes.Object_6.geometry} material={materials.Button} /> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_4.geometry}
        material={new THREE.MeshBasicMaterial({ color: "white" })}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_6.geometry}
        material={new THREE.MeshBasicMaterial({ color: props.color })}
      />
    </group>
  );
}

useGLTF.preload("/button-transformed.glb");
