import * as THREE from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Gltf, OrbitControls } from "@react-three/drei";
import { useEffect, useRef } from "react";
import { Model } from "./Cyberpunk";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { Button } from "./Button";
import {
  Bloom,
  EffectComposer,
  ToneMapping,
} from "@react-three/postprocessing";
const Scene = ({ scrollbar }) => {
  const { camera } = useThree();
  const refScrollPercent = useRef(0);
  const refAnimationScripts = useRef([]);
  const refBodyHeight = useRef(0);
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  function lerp(x, y, a) {
    return (1 - a) * x + a * y;
  }

  // Used to fit the lerps to start and end at specific scrolling percentages
  function scalePercent(start, end) {
    return (refScrollPercent.current - start) / (end - start);
  }

  useEffect(() => {
    if (!scrollbar) return;
    gsap.to(scrollbar, {
      duration: 0.75,
      scrollTo: 70,
      ease: "none",
    });

    scrollbar.addListener((s) => {
      refBodyHeight.current = s.limit.y;
      refScrollPercent.current = (s.offset.y * 100) / s.limit.y;

      if (refScrollPercent.current === 100) {
        //Con esta opcion vuelve al inicio el scroll
        // scrollbar.setPosition(s.offset.y, 0, { duration: 0 });
      }
      playScrollAnimations();

      //   document.getElementById("scrollProgress").innerText =
      //     "Scroll Progress : " + refScrollPercent.current.toFixed(2);
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [scrollbar]);
  function playScrollAnimations() {
    refAnimationScripts.current.forEach((a) => {
      if (
        refScrollPercent.current >= a.start &&
        refScrollPercent.current < a.end
      ) {
        a.func();
      }
    });
  }

  useEffect(() => {
    refAnimationScripts.current.push({
      start: 0,
      end: 23,
      func: () => {
        const start = 0;
        const end = 23;
        const porcentaje = scalePercent(start, end);
        // gsap.to(camera.position, {
        //   x: lerp(0, 2, porcentaje),
        //   y: lerp(1, 1.5, porcentaje),
        //   z: lerp(1, -3, porcentaje),
        //   duration: 0,
        // });
        // gsap.to(camera.rotation, {
        //   y: lerp(0, Math.PI * 1.5, porcentaje),
        //   duration: 0,
        // });

        camera.position.x = lerp(0, 2, porcentaje);
        camera.position.y = lerp(1, 1.5, porcentaje);
        camera.position.z = lerp(1, -3, porcentaje);
        //La siguiente linea para que gire para el otro lado
        // camera.rotation.y = lerp(0, Math.PI * 1.5, porcentaje);
        camera.rotation.y = lerp(0, -Math.PI * 0.5, porcentaje);
      },
    });
    refAnimationScripts.current.push({
      start: 33,
      end: 60,
      func: () => {
        const start = 33;
        const end = 60;

        const cameraX = 2;
        const cameraY = 1.5;
        const cameraZ = -3;
        const cameraRotationY = Math.PI * 1.5;
        const porcentaje = scalePercent(start, end);
        // console.log(porcentaje);
        // gsap.to(camera.position, {
        //   x: lerp(cameraX, -2, porcentaje),
        //   y: lerp(cameraY, 1.5, porcentaje),
        //   z: lerp(cameraZ, -3, porcentaje),
        //   duration: 0,
        // });
        // gsap.to(camera.rotation, {
        //   y: lerp(cameraRotationY, Math.PI * 0.5, porcentaje),
        //   duration: 0,
        // });
        camera.position.x = lerp(cameraX, -2, porcentaje);
        camera.position.y = lerp(cameraY, 1.5, porcentaje);
        camera.position.z = lerp(cameraZ, -3, porcentaje);
        camera.rotation.y = lerp(cameraRotationY, Math.PI * 0.5, porcentaje);
      },
    });
    refAnimationScripts.current.push({
      start: 70,
      end: 100,
      func: () => {
        const start = 70;
        const end = 100;

        const cameraX = -2;
        const cameraY = 1.5;
        const cameraZ = -3;
        const cameraRotationY = Math.PI * 0.5;
        const porcentaje = scalePercent(start, end);
        // gsap.to(camera.position, {
        //   x: lerp(cameraX, 0, porcentaje),
        //   y: lerp(cameraY, 1.5, porcentaje),
        //   z: lerp(cameraZ, 2, porcentaje),
        //   duration: 0,
        // });
        // gsap.to(camera.rotation, {
        //   y: lerp(cameraRotationY, Math.PI * 1, porcentaje),
        //   duration: 0,
        // });

        camera.position.x = lerp(cameraX, 0, porcentaje);
        camera.position.y = lerp(cameraY, 1.5, porcentaje);
        camera.position.z = lerp(cameraZ, 2, porcentaje);
        camera.rotation.y = lerp(cameraRotationY, Math.PI * 1, porcentaje);
      },
    });
  }, []);

  //   refAnimationScripts.current = [];
  useEffect(() => {
    /**
     * First set
     *
     */
    // camera.position.set(2, 1.5, -3);
    // camera.lookAt(0, 1.5, 0);
    // camera.rotation.y = Math.PI * 1.5;
    /**
     *
     * Second set
     *
     */
    // camera.position.set(-2, 1.5, -3);
    // camera.lookAt(0, 1.5, 0);
    // camera.rotation.y = Math.PI * 0.5;
    /**
     *
     * Third set
     *
     */
    // camera.position.set(0, 1.5, 2);
    // camera.lookAt(0, 1.5, 0);
    // camera.rotation.y = Math.PI * 1;
  }, []);
  return (
    <>
      {/** The bloom pass is what will create glow, always set the threshold to 1, nothing will glow
         /*  except materials without tonemapping whose colors leave RGB 0-1 */}
      <EffectComposer disableNormalPass>
        <Bloom mipmapBlur luminanceThreshold={1} />
        <ToneMapping
          adaptive
          resolution={256}
          middleGrey={0.4}
          maxLuminance={16.0}
          averageLuminance={1.0}
          adaptationRate={1.0}
        />
      </EffectComposer>
      <Model />
      <ambientLight intensity={0.7} />

      <pointLight
        position={[2, 1.5, -3]}
        color="white"
        intensity={1.5}
        // distance={2}
      />

      <pointLight
        position={[0, 1.5, 3]}
        color="white"
        intensity={1.5}
        // distance={2}
      />

      <Button
        color="red"
        scale={[0.2, 0.2, 0.2]}
        position={[5, 2.1, -1.54]}
        rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]}
        onClick={() => {
          const altura = refBodyHeight.current;
          gsap.to(scrollbar, {
            duration: 4,
            scrollTo: altura,
            ease: "none",
          });
        }}
      />
      <Button
        color="blue"
        scale={[0.2, 0.2, 0.2]}
        position={[5, 2.1, -4.5]}
        rotation={[Math.PI * 0.5, 0, Math.PI * 0.5]}
        onClick={() => {
          const altura = (refBodyHeight.current * 70) / 100;

          gsap.to(scrollbar, {
            duration: 2,
            scrollTo: altura,
            ease: "none",
          });
        }}
      />
      {/* <OrbitControls panSpeed={0.5} /> */}
    </>
  );
};

export default Scene;
